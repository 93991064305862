import { useMutation } from "@tanstack/react-query";
import { ServerError } from "features/errors/serverError.page";
import { Divider } from "features/layout/components/divider.component";
import { FormEvent, useState } from "react";
import { Form, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { IDecisionNodeData } from "types/decisionTree.types";
import { Language } from "types/enums/language.enum";
import { isArticleError } from "utils/isValidationError.helper";
import { ArticleEditHeader } from "../editor/components/articleEditHeader.component";
import { DomainSelector } from "../editor/components/domainSelector.component";
import {
  ExpertPicker,
  IPickedExpert,
} from "../editor/components/expertPicker.component";
import DecisionTreeEditor from "./components/decisionTreeEditor.component";
import { createDecisionTree } from "./decisionTree.query";

export const NewDecisionTreeEditor = () => {
  const { t } = useTranslation();
  const {
    mutate,
    data: updatedArticle,
    isLoading: isLoadingPost,
    error: createErrors,
    isSuccess: isSuccessPost,
  } = useMutation({
    mutationFn: createDecisionTree,
  });

  const [title, setTitle] = useState("");
  const [domain, setDomain] = useState("-1");
  const [invalidDomain, setInvalidDomain] = useState(false);
  const [linkWords, setLinkWords] = useState("");
  const [description, setDescription] = useState("");

  const [selectedExperts, setSelectedExperts] = useState<IPickedExpert[]>([]);
  const [rootNodes, setRootNodes] = useState<IDecisionNodeData[]>([]);

  if (isSuccessPost) {
    return (
      <Navigate
        to={`${t("fullRoutes.allArticles")}/${updatedArticle.data.id}`}
      />
    );
  }

  if (createErrors !== null && !isArticleError(createErrors)) {
    return <ServerError />;
  }
  const errors = createErrors ? createErrors.response.data : {};
  const save = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (domain === "-1") {
      setInvalidDomain(true);
    }
    mutate({
      article_translation_set: [
        {
          decision_tree_description: description,
          language: Language.NL,
          title: title,
        },
      ],
      article_author: selectedExperts.map((expert, i) => ({
        author: expert.id,
        order: i,
      })),
      link_name_set: linkWords
        .split(",")
        .filter((linkName) => linkName !== "")
        .map((linkName) => ({
          link_name: linkName.trim(),
          language: Language.NL,
        })),
      subdomain: Number(domain.split("/")[1]),
      decision_tree: rootNodes,
    });
  };
  return (
    <Form onSubmit={save}>
      <article className={"edit-article"}>
        <ArticleEditHeader
          isUpdating={isLoadingPost}
          title={title}
          setTitle={setTitle}
          error={errors}
        />
        <div className={"edit-body"}>
          <div className="metadata">
            <div className={"input-cont"}>
              <DomainSelector
                domain={domain}
                setDomain={(newDomain) => {
                  setDomain(newDomain);
                  setInvalidDomain(false);
                }}
                isInvalid={invalidDomain || !!errors.subdomain}
              />

              <FormGroup className={"form-group"}>
                <FormLabel>Afkortingen, synoniemen</FormLabel>
                <FormControl
                  type={"text"}
                  value={linkWords}
                  isInvalid={!!errors.link_name_set}
                  onChange={(e) => setLinkWords(e.target.value)}
                />
              </FormGroup>
              <Form.Group className="mb-3">
                <Form.Label>Decision node description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="input-cont expert-picker-cont">
              <ExpertPicker
                errors={errors}
                selectedExperts={selectedExperts}
                setSelectedExperts={setSelectedExperts}
              />
            </div>
          </div>
          <Divider />
          <DecisionTreeEditor
            rootNodes={rootNodes}
            setRootNodes={setRootNodes}
          />
        </div>
      </article>
    </Form>
  );
};
