import { PropsWithChildren } from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";

export const LoadingButton = (
  props: PropsWithChildren<{ isLoading: boolean } & ButtonProps>
) => {
  const { isLoading, children, ...buttonProps } = props;

  return (
    <Button
      {...buttonProps}
      disabled={buttonProps.disabled || props.isLoading}
      className={`loading-button ${buttonProps.className}`}
    >
      {props.isLoading && (
        <Spinner animation="border" role="status" size="sm">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <span className={props.isLoading ? "invisible" : ""}>
        {props.children}
      </span>
    </Button>
  );
};
