import slugify from "slugify";
import { IDomain } from "types";
import { Language } from "types/enums/language.enum";
import { getTranslation } from "./getTranslation.helper";

export const getDomainBySlug = (
  items: IDomain[],
  slug: string | undefined,
  language: Language
) => {
  if (slug === undefined) {
    return undefined;
  }
  return items.find((d) => {
    const translation = getTranslation(d.domain_translation_set, language);
    const name = translation?.name ?? d.name;
    return slugify(name).toLowerCase() === slug;
  });
};
