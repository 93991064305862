import Skeleton from "react-loading-skeleton";
import { Loading } from "types";

export const TabNavigation = (
  props: Loading<{
    className?: string;
    titles: string[];
    disabledTitles?: string[];
    onTabClick?(index: number): void;
    activeIndex?: number;
    errors?: { title?: string[]; body?: string[] }[];
  }>
) => {
  if (props.isLoading) {
    return <Skeleton containerClassName="chapter-titles-cont skeleton" />;
  }

  const onTabClick = props.onTabClick ?? (() => {});
  const isError = (index: number) => {
    if (!props.errors) {
      return false;
    }
    if (
      props.errors[index] === undefined ||
      (!props.errors[index].title && !props.errors[index].body)
    ) {
      return false;
    }
    return true;
  };
  const activeTitles = props.titles.map((title, i) => (
    <ChapterNavItem
      isInvalid={isError(i)}
      active={i === props.activeIndex}
      name={title}
      key={`${title} - ${i}`}
      onClick={() => onTabClick(i)}
    />
  ));

  const disabledTitles = (props.disabledTitles ?? []).map((title, i) => (
    <ChapterNavItem
      active={false}
      name={title}
      key={`${title} - ${i}`}
      disabled={true}
    />
  ));
  return (
    <ul className={`chapter-titles-cont ${props.className ?? ""}`}>
      {activeTitles}
      {disabledTitles}
    </ul>
  );
};

const ChapterNavItem = (props: {
  name: string;
  active: boolean;
  disabled?: boolean;
  isInvalid?: boolean;
  onClick?(): void;
}) => {
  return (
    <li
      onClick={props.onClick}
      className={`chapter-title ${props.isInvalid ? "invalid" : ""} ${
        props.active ? " active" : ""
      } ${props.disabled ? "disabled" : ""}`}
    >
      {props.name}
    </li>
  );
};
