import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLanguage } from "hooks/useLanguage.hook";
import { FormEvent, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IArticle } from "types";
import { IDecisionNodeData } from "types/decisionTree.types";
import { ArticleType } from "types/enums/articleType.enum";
import { isArticleError } from "utils/isValidationError.helper";
import DecisionTreeEditor from "../decision-tree/components/decisionTreeEditor.component";
import { ArticleEditHeader } from "../editor/components/articleEditHeader.component";
import { IPickedExpert } from "../editor/components/expertPicker.component";
import { MetaDataEditor } from "../editor/components/metaDataEditor.component";
import { updateDecisionTree } from "./decisionTree.query";
import { formatDecisionTree } from "./utils/formatDecisionTree.helper";

export const UpdateDecisionTreeEditor = ({
  article,
}: {
  article: IArticle;
}) => {
  const { articleId } = useParams<{ articleId?: string }>();
  const language = useLanguage();
  const queryClient = useQueryClient();
  const {
    mutate,
    isLoading: isUpdating,
    error: createErrors,
  } = useMutation({
    mutationFn: updateDecisionTree,
    onSuccess: () => {
      queryClient.invalidateQueries(["article-edit", articleId]);
      setAlert({
        variant: "success",
        text: "Het artikel is successvol bijgewerkt.",
      });
    },
    onError: () => {
      setAlert({
        variant: "danger",
        text: "Er is iets misgegaan. Kijk de ingevoerde gegevens na.",
      });
    },
  });
  const [alert, setAlert] = useState<
    { text: string; variant: "success" | "danger" } | undefined
  >(undefined);
  const [title, setTitle] = useState("");
  const [domain, setDomain] = useState(
    `${article.subdomain.domain.id}/${article.subdomain.id}`
  );
  const [invalidDomain, setInvalidDomain] = useState(false);
  const [linkWords, setLinkWords] = useState(
    article.link_name_set.map((linkName) => linkName.link_name).join(", ")
  );
  const [rootNodes, setRootNodes] = useState<IDecisionNodeData[]>([]);

  const [selectedExperts, setSelectedExperts] = useState<IPickedExpert[]>(
    article.article_author
      .sort((a, b) => a.order - b.order)
      .map((expert) => ({
        id: expert.author.id,
        name: `${expert.author.user.first_name} ${expert.author.user.last_name}`,
      }))
  );
  const [description, setDescription] = useState("");
  console.log("root: ", rootNodes);

  const save = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (domain === "-1") {
      setInvalidDomain(true);
    }
    console.log("saving root nodes: ", rootNodes);

    const body = formatDecisionTree(
      title,
      domain,
      selectedExperts,
      rootNodes,
      linkWords,
      description,
      language
    );
    mutate({ body, id: Number(articleId) });
  };

  const errors = isArticleError(createErrors) ? createErrors.response.data : {};

  return (
    <article className={"edit-article"}>
      <Form onSubmit={save}>
        {alert ? (
          <Alert
            className="alert"
            variant={alert.variant}
            dismissible
            onClose={() => setAlert(undefined)}
          >
            {alert.text}
          </Alert>
        ) : null}

        <ArticleEditHeader
          article={article}
          isUpdating={isUpdating}
          title={title}
          setTitle={setTitle}
          error={errors}
          setAlert={setAlert}
        />
        <div className={"edit-body"}>
          <MetaDataEditor
            type={ArticleType.decisionTree}
            disabled={isUpdating}
            invalidDomain={invalidDomain}
            domain={domain}
            errors={errors}
            linkWords={linkWords}
            selectedExperts={selectedExperts}
            setDomain={(newDomain) => {
              setDomain(newDomain);
              setInvalidDomain(false);
            }}
            setLinkWords={setLinkWords}
            setSelectedExperts={setSelectedExperts}
            description={description}
            setDescription={setDescription}
          />
          <DecisionTreeEditor
            article={article}
            rootNodes={rootNodes}
            setRootNodes={setRootNodes}
          />
        </div>
      </Form>
    </article>
  );
};
