import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Paywall = () => {
  const { t } = useTranslation();
  return (
    <div className={"article-paywall"}>
      <h3>{t("articles.paywall.continueReading")}</h3>
      <p>{t("articles.paywall.getSubscription")}</p>
      <Link to="/nl/abonnement">
        <Button variant={"primary"}>{t("articles.paywall.orderNow")}</Button>
      </Link>
      <p>
        <Trans
          i18nKey="articles.paywall.alreadyAnAccount"
          components={{
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            a: <Link to={t("fullRoutes.login")} />,
          }}
        />
      </p>
    </div>
  );
};
