import { Button } from "react-bootstrap";
export const NotFound = () => {
  return (
    <div className="error-page">
      <h1>404</h1>
      <h2>De opgegeven pagina is niet gevonden</h2>
      <Button href="/nl/">Naar homepagina</Button>
    </div>
  );
};
