import axios from "axios";
import { IExpert } from "types";
import { Language } from "types/enums/language.enum";

export const getExperts = async (language: Language): Promise<IExpert[]> => {
  const product = process.env.REACT_APP_PRODUCT;
  const { data } = await axios.get(`/capripedia/expert/`, {
    params: {
      lan: language,
      pedia_type: product,
    },
  });
  return data;
};
