import { IPickedExpert } from "features/admin/editor/components/expertPicker.component";
import { IDecisionNodeData, IDecisionTreeData } from "types/decisionTree.types";
import { Language } from "types/enums/language.enum";

export const formatDecisionTree = (
  title: string,
  domain: string,
  selectedExperts: IPickedExpert[],
  nodes: IDecisionNodeData[],
  linkWords: string,
  description: string,
  language: Language
): IDecisionTreeData => {
  const sudomainId = Number(domain.split("/")[1]);
  return {
    subdomain: sudomainId,
    article_author: selectedExperts.map((expert, i) => ({
      author: expert.id,
      order: i,
    })),
    decision_tree: nodes,
    link_name_set:
      linkWords === ""
        ? []
        : linkWords.split(",").map((linkName) => ({
            link_name: linkName.trim(),
            language,
          })),
    article_translation_set: [
      { title, language, decision_tree_description: description },
    ],
  };
};
