import axios from "axios";
import { IShallowArticle } from "types";
import { FilterType } from "types/enums/filterType.enum";

export const getArticles = async (
  filter: FilterType
): Promise<IShallowArticle[]> => {
  const product = process.env.REACT_APP_PRODUCT;
  const { data } = await axios.get(`capripedia/article/all_articles/`, {
    params: { pedia_type: product, published: filter },
  });
  return data.articles;
};
