import { useQuery } from "@tanstack/react-query";
import { IUser } from "types";
import useLocalStorage from "use-local-storage";
import { getProfileIfToken } from "utils/auth/getProfileIfToken.helper";
import { getToken } from "utils/auth/getToken.helper";

export const useAuth = () => {
  const token = getToken();
  const [user, setUser] = useLocalStorage<IUser | null>("user", null);
  const enabled = user === null
  
  const { isLoading } = useQuery({
    queryKey: ["profile", token],
    queryFn: getProfileIfToken,
    retry: 0,
    enabled: enabled,
    onSuccess: (data) => {
      setUser(data);
    }
  });
  if (token === undefined) {
    return { user: null, isLoading: false };
  }
  return { user, isLoading: isLoading && user === null };
};
