import { useQuery } from "@tanstack/react-query";
import { getDomains } from "features/domains/domain.query";
import { useLanguage } from "hooks/useLanguage.hook";
import { PropsWithChildren } from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoDocuments } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { IDomain, ISubdomain } from "types";
import { getDomainData } from "utils";
import { getSubdomainBySlug } from "utils/getSubdomainBySlug.helper";
export const Sidebar = (props: PropsWithChildren<{}>) => {
  const { t } = useTranslation();
  const lang = useLanguage();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["domain"],
    queryFn: () => getDomains(lang),
    cacheTime: 5 * 60 * 1000,
  });
  if (isLoading) {
    return (
      <div className={"sidebar d-none d-xl-block"}>
        <h2>
          <IoDocuments /> {t("sidebar.domains")}
        </h2>
        <Nav></Nav>
        {props.children}
      </div>
    );
  } else if (isError) {
    return (
      <div className={"sidebar d-none d-xl-block"}>
        <h2>
          <IoDocuments /> {t("sidebar.domains")}
        </h2>
      </div>
    );
  }

  return (
    <div className={"sidebar d-none d-xl-block"}>
      <h2>
        <IoDocuments /> {t("sidebar.domains")}
      </h2>
      <Nav>
        {data.map((domain) => (
          <DomainItem {...domain} key={domain.name} />
        ))}
      </Nav>
      {props.children}
    </div>
  );
};

const DomainItem = (domain: IDomain) => {
  const { domainSlug, subdomainSlug } = useParams<{
    domainSlug?: string;
    subdomainSlug?: string;
  }>();
  const language = useLanguage();
  const [name, link, slug] = getDomainData(language, domain, domainSlug);
  let item: JSX.Element | null = null;
  if (domainSlug !== undefined && subdomainSlug !== undefined) {
    const subdomain = getSubdomainBySlug(
      [domain],
      domainSlug,
      subdomainSlug,
      language
    );
    item = subdomain !== undefined ? <SubdomainItem {...subdomain} /> : null;
  }
  return (
    <Nav.Item>
      <Nav.Link
        as={Link}
        className={slug === domainSlug ? "active" : ""}
        to={link}
      >
        {name}
      </Nav.Link>
      {item}
    </Nav.Item>
  );
};

const SubdomainItem = (subdomain: ISubdomain) => {
  const language = useLanguage();
  const { domainSlug } = useParams<{
    domainSlug?: string;
    subdomainSlug?: string;
  }>();
  const [name, link] = getDomainData(language, subdomain, domainSlug);
  return (
    <Nav.Item>
      <Nav.Link as={Link} to={link} className={"nested-link"}>
        {name}
      </Nav.Link>
    </Nav.Item>
  );
};
