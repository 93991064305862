import axios from "axios";
import { IShallowArticle, Paginated } from "types";
import { Language } from "types/enums/language.enum";

export const getSearchResults = async (
  keyword: string | null, language: Language
): Promise<Paginated<IShallowArticle> | undefined> => {
  if (keyword === null) {
    return Promise.resolve(undefined);
  }
  const product = process.env.REACT_APP_PRODUCT;
  const { data } = await axios.get("capripedia/article/search/", {
    params: { keyword, product, lan: language },
  });
  return data;
};
