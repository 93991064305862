import { Language } from "types/enums/language.enum";
import { fullRoutes } from "./helpers/fullRoutes.helper";
import { getProductName } from "utils";

const locale: Language = Language.NL;

export const routes = {
  domains: `domeinen`,
  experts: `experten`,
  about: `over-capripedia`,
  search: `zoeken`,
  login: `login`,
  allArticles: `artikels`,
  addArticle: `nieuw-artikel`,
  newArticle: `artikel`,
  newDecisionTree: `wijzer`,
  profile: `profile`,
  logout: `logout`,
  subscription: `abonnement`,
  buySubscription: `kopen`,
  changeSubscription: `aanpassen`,
  askAI: `vraag-ai`,
};

export const nl = {
  locale,
  sidebar: {
    domains: "Domeinen",
    authors: "Artikel auteurs",
  },
  header: {
    search: "Zoeken",
    allArticles: "Alle artikels",
    addArticles: "Artikel toevoegen",
    login: "Inloggen",
    profile: "Mijn profiel",
    logout: "Uitloggen",
    dutch: "Nederlands",
    english: "Engels",
    language: "Taal",
  },
  homepage: {
    domains: "Domeinen",
    experts: "Experten",
    about: `Over ${getProductName()}`,
    search: `Zoeken in ${getProductName()}`,
  },
  domains: {
    subdomainWithCount_one: "({{count}} subdomein)",
    subdomainWithCount_other: "({{count}} subdomeinen)",
    domainWithCount_one: "({{count}} domein)",
    domainWithCount_other: "({{count}} domeinen)",
  },
  articles: {
    articleWithCount_one: "{{count}} artikel",
    articleWithCount_other: "{{count}} artikels",
    inDraft:
      "Dit artikels is nog in concept. Enkel editors, admins en experten kunnen het momenteel zien.",
    createdAt: "Gemaakt op",
    paywall: {
      continueReading: "Verder lezen?",
      getSubscription: `Neem een abonnement op ${getProductName()} en krijg onbeperkt toegang tot alle artikels.`,
      orderNow: "Bestel nu",
      alreadyAnAccount:
        "Heb je al een account? Dan moet je eerst <a>inloggen</a>.",
    },
    filters: {
      all: "Alle artikels",
      published: "Gepubliceerde artikels",
      draft: "Draft artikels",
    },
    draft: "Concept",
  },
  addArticle: {
    addArticle: "Artikel toevoegen",
    addDecisionTree: "Wijzer toevoegen",
  },
  experts: {
    contact: "Contacteer",
  },
  login: {
    email: "E-mailadres",
    password: "Wachtwoord",
    forgot: "Wachtwoord vergeten?",
    stayLoggedIn: "Ingelogd blijven",
    login: "Inloggen",
    noAccount: "Nog geen account?",
    register: "Registreer",
    invalidCredentials: "Ongeldig e-mailadres of wachtwoord.",
    invalidEmail: "Ongeldig e-mailadres",
    invalidPassword: "Ongeldig wachtwoord",
  },
  profile: {
    myProfile: "Mijn profiel",
    personalData: "Persoonlijke gegevens",
    firstName: "Voornaam",
    lastName: "Achternaam",
    email: "E-mailadres",
    telephone: "Mobiel nummber",
    addressData: "Adres gegevens",
    streetName: "Straatnaam",
    number: "Huisnummer",
    country: "Land",
    postalCode: "Postcode",
    city: "Gemeente",
    save: "Gegevens opslaan",
    loginData: "Login gegevens",
    password: "Wachtwoord",
    savePassword: "Wachtwoord opslaan",
    countries: {
      BE: "België",
      NL: "Nederland",
    },
    dataSavedSuccessfully: "Gegevens successvol bijgewerkt",
    emailInUse: "Er bestaat reeds een gebruiker met dit e-mailadres.",
    passwordSavedSuccess: "Wachtwoord successvol bijgewerkt",
    passwordLength: "Het wachtwoord moet minstens 8 tekens bevatten",
  },
  fullRoutes: fullRoutes(routes, locale),
  routes,
};
