import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "./components/header.component";

export const HeaderLayout = (
  props: PropsWithChildren<{ hideSearch?: boolean }>
) => {
  return (
    <div className={"layout-content"}>
      <Header hideSearch={props.hideSearch} />
      <div className={"app-cont"}>
        <Outlet />
      </div>
    </div>
  );
};
