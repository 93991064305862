import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { nl } from "./locales/nl";
import { en } from "./locales/en";
import { i18nextPlugin } from "translation-check";
import detector from "i18next-browser-languagedetector";

export const resources = {
  en: {
    translation: en,
  },
  nl: {
    translation: nl,
  },
};

export const defaultNS = "translation";
i18n
  .use(detector)
  .use(i18nextPlugin)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: defaultNS,
    fallbackLng: "nl",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["path", "navigator"],
      lookupFromPathIndex: 0,
    },
  });

export default i18n;
