import { useQuery } from "@tanstack/react-query";
import { NotFound } from "features/errors/notFound.page";
import { ServerError } from "features/errors/serverError.page";
import { useLanguage } from "hooks/useLanguage.hook";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { getDomainBySlug } from "utils";
import { getTranslation } from "utils/getTranslation.helper";
import { Divider } from "../layout/components/divider.component";
import { SectionTitle } from "../layout/components/sectionTitle.component";
import { DomainList } from "./components/domainList";
import { getDomains } from "./domain.query";

export const Domains = () => {
  const { domainSlug } = useParams<{ domainSlug?: string }>();
  const language = useLanguage();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["domain"],
    queryFn: () => getDomains(language),
    cacheTime: 5 * 60 * 1000,
  });
  const { t } = useTranslation();
  if (isError) {
    return <ServerError />;
  }

  const domain = data && getDomainBySlug(data, domainSlug, language);
  if (!isLoading && domain === undefined && domainSlug !== undefined) {
    return <NotFound />;
  }

  let title: string | undefined;
  if (domain) {
    const translation = getTranslation(domain.domain_translation_set, language);
    title = translation?.name ?? domain.name;
  }

  const resultCount =
    domainSlug === undefined ? data?.length : domain?.subdomain_set.length;
  return (
    <div className={"list-cont"}>
      <div className={"result-count-cont"}>
        {domainSlug !== undefined ? (
          <SectionTitle
            title={title ?? ""}
            isLoading={isLoading}
            smallText={t("domains.subdomainWithCount", {
              count: resultCount,
            })}
          />
        ) : (
          <ResultCount resultCount={resultCount} isLoading={isLoading} />
        )}
        <Divider />
      </div>
      <DomainList />
    </div>
  );
};

const ResultCount = (
  props: { resultCount?: number; isLoading: false } | { isLoading: true }
) => {
  const { t } = useTranslation();
  if (props.isLoading) {
    return <Skeleton containerClassName="result-count" />;
  }
  return (
    <div className={"result-count"}>
      {t("domains.domainWithCount", {
        count: props.resultCount,
      })}
    </div>
  );
};
