import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

export const DomainItem = (props: {
  name: string;
  link: string;
  className?: string;
  icon?: JSX.Element;
  badge?: string;
}) => {
  return (
    <li className={`list-item ${props.className ?? ""}`}>
      <Link className={"list-link"} to={props.link}>
        <span>
          {props.icon} {props.name}
        </span>
        {props.badge && <Badge bg="warning">{props.badge}</Badge>}
      </Link>
    </li>
  );
};
