import { useLanguage } from "hooks/useLanguage.hook";
import Skeleton from "react-loading-skeleton";
import { IShallowArticle, ISubdomain } from "types";
import { Language } from "types/enums/language.enum";
import { removePediaType } from "utils";
import { getTranslation } from "utils/getTranslation.helper";
import { DomainItem } from "./domainItem";

export const SubdomainList = (
  props:
    | (ISubdomain & {
        isLoading?: false;
        domainSlug: string;
        subdomainSlug: string;
      })
    | { isLoading: true }
) => {
  const language = useLanguage();
  if (props.isLoading) {
    const skeletons = Array(6)
      .fill(1)
      .map((_, i) => <Skeleton containerClassName="list-item" key={i} />);
    return <ul className={"list"}>{skeletons}</ul>;
  }
  const articleItems = props.article_set.map((article) => {
    const [name, link] = getNameAndLink(
      article,
      props.domainSlug,
      props.subdomainSlug,
      language
    );

    return (
      <DomainItem
        badge={article.date_published === null ? "Draft" : undefined}
        name={name}
        link={link}
        key={name}
      />
    );
  });
  return <ul className={"list"}>{articleItems}</ul>;
};

const getNameAndLink = (
  article: IShallowArticle,
  domainSlug: string,
  subdomainSlug: string,
  language: Language
) => {
  const translation = getTranslation(article.article_translation_set, language);
  const link = `/${language}/${domainSlug}/${subdomainSlug}/${removePediaType(
    article.slug
  )}`;

  return [translation?.title ?? article.title, link];
};
