import { useMutation } from "@tanstack/react-query";
import { FormEvent, useState } from "react";
import {
  FormGroup,
  FormLabel,
  Button,
  Form,
  FormControl,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IUser } from "types";
import { DeepPartial } from "types/util.types";
import { updateProfile } from "../profile.query";
import { PasswordInput } from "./passwordInput.component";

export const ChangePasswordForm = (props: { email: string }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");

  const { mutate, isSuccess: isSuccessUpdate } = useMutation<
    unknown,
    any,
    DeepPartial<IUser & { password: string }>,
    unknown
  >({
    mutationFn: updateProfile,
  });

  const isValid = password.length >= 8;
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({ password });
  };

  return (
    <Form className="profile-edit" onSubmit={handleSubmit}>
      {isSuccessUpdate && (
        <Alert variant="success">{t("profile.passwordSavedSuccess")}</Alert>
      )}
      <div className="edit-group">
        <h3>{t("profile.loginData")}</h3>
        <FormGroup className="form-group">
          <FormLabel>{t("profile.email")}</FormLabel>
          <FormControl
            autoComplete="username email"
            type="email"
            value={props.email}
            disabled={true}
          />
        </FormGroup>
        <FormGroup className="form-group">
          <FormLabel>
            {t("profile.password")}
            <span className="required">*</span>
          </FormLabel>
          <PasswordInput
            autoComplete={"new-password"}
            value={password}
            onChange={setPassword}
            isValid={isValid}
          />

          <span className={`info-message ${isValid && "invisible"}`}>
            {t("profile.passwordLength")}
          </span>
        </FormGroup>
      </div>
      <div className="btn-cont">
        <Button variant="danger" disabled={password.length < 8} type="submit">
          {t("profile.savePassword")}
        </Button>
      </div>
    </Form>
  );
};
