import { useLanguage } from "hooks/useLanguage.hook";
import { forwardRef } from "react";
import slugify from "slugify";
import { IChapter } from "types";
import { getTranslation } from "utils/getTranslation.helper";
export const Chapter = forwardRef<HTMLHeadingElement, IChapter>(
  (props, ref) => {
    const language = useLanguage();
    const translation = getTranslation(props.chapter_translation_set, language);

    const body =
      translation?.body_with_links ??
      "This chapter is not available in your language";
    return (
      <section className="chapter-content">
        <h3 ref={ref} id={slugify(props.title)}>
          {translation?.title.toUpperCase()}
        </h3>
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </section>
    );
  }
);
