import { Button } from "react-bootstrap";
export const ServerError = () => {
  return (
    <div className="error-page">
      <h1>500</h1>
      <h2>Er is iets misgegaan. Probeer later opnieuw.</h2>
      <Button href="/nl/">Naar homepagina</Button>
    </div>
  );
};
