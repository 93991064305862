import Skeleton from "react-loading-skeleton";
import { Loading } from "types";

export const SectionTitle = (
  props: Loading<{
    title: string;
    smallText: string;
  }>
) => {
  return (
    <div className={"title-cont"}>
      {props.isLoading ? (
        <h1>
          <Skeleton containerClassName="skeleton" />
        </h1>
      ) : (
        <h1>{props.title}</h1>
      )}
      {props.isLoading ? (
        <Skeleton containerClassName="link-words skeleton" />
      ) : (
        <div className={"link-words"}>{props.smallText}</div>
      )}
    </div>
  );
};
