import { useQuery } from "@tanstack/react-query";
import { DomainItem } from "features/domains/components/domainItem";
import { useLanguage } from "hooks/useLanguage.hook";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { FilterType } from "types/enums/filterType.enum";
import { DecisionTreeIcon } from "utils/components/decisionTreeIcon.component";
import { getArticles } from "../allArticles.query";
import { getNameAndLink } from "../helpers/getNameAndLink";

export const ArticleList = (props: { filter: FilterType }) => {
  const { t } = useTranslation();
  const { data: articles, isLoading } = useQuery({
    queryKey: ["articles", props.filter],
    queryFn: () => getArticles(props.filter),
  });

  const language = useLanguage();
  if (isLoading) {
    const skeletons = Array(6)
      .fill(1)
      .map((_, i) => <Skeleton containerClassName="list-item" key={i} />);
    return <ul className={"list"}>{skeletons}</ul>;
  }
  if (!articles) {
    return <div></div>;
  }
  const articleItems = articles.map((article) => {
    const [name, link] = getNameAndLink(article, language);

    return (
      <DomainItem
        name={name}
        link={link}
        badge={
          article.date_published === null ? t("articles.draft") : undefined
        }
        key={article.id}
        icon={
          article.type === "decision_tree" ? <DecisionTreeIcon /> : undefined
        }
      />
    );
  });
  return <ul className={"list"}>{articleItems}</ul>;
};
