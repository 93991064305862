import { useLanguage } from "hooks/useLanguage.hook";
import { useNavigate } from "react-router-dom";
import { IShallowArticle } from "types";
import { removePediaType, stripTags } from "utils";
import { DecisionTreeIcon } from "utils/components/decisionTreeIcon.component";
import { getTranslation } from "utils/getTranslation.helper";

export const ArticlePreview = ({ article }: { article: IShallowArticle }) => {
  const language = useLanguage();
  const navigate = useNavigate();
  const onClick = () => {
    navigate(
      `/${language}/${article.subdomain.domain.domain_language_slug}/${
        article.subdomain.subdomain_language_slug
      }/${removePediaType(article.slug)}`
    );
  };

  const body =
    article.type === "article" && article.result_body !== ""
      ? stripTags(article.result_body ?? "").slice(0, 255) + "..."
      : null;

  const linkNames =
    article.link_name_set.length > 0
      ? article.link_name_set.map((linkName) => linkName.link_name).join(", ")
      : undefined;

  const title = getTranslation(article.article_translation_set, language)?.title ?? article.title;
  return (
    <section
      className={`article-preview ${body === null && "empty-body"}`}
      onClick={onClick}
    >
      <div className={"title"}>
        <h3>
          {article.type === "decision_tree" && <DecisionTreeIcon />}
          {title}
        </h3>
        {linkNames !== undefined ? <span>({linkNames})</span> : null}
      </div>
      {body && (
        <div
          className={"description"}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      )}
    </section>
  );
};
