import { useState } from "react";
import { FormControl } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const PasswordInput = (props: {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  isInvalid?: boolean;
  isValid?: boolean;
  autoComplete?: string;
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="password-input">
      <FormControl
        autoComplete={props.autoComplete}
        type={visible ? "text" : "password"}
        required={true}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        disabled={props.disabled}
        isInvalid={props.isInvalid}
        isValid={props.isValid}
      />
      {visible ? (
        <FaEyeSlash className="icon" onClick={() => setVisible(false)} />
      ) : (
        <FaEye className="icon" onClick={() => setVisible(true)} />
      )}
    </div>
  );
};
