import slugify from "slugify";
import {
  IDomain,
  ISubdomain,
  IDomainTranslation,
  ISubdomainTranslation,
} from "types";
import { Language } from "types/enums/language.enum";
import { isSubdomain } from "./isSubdomain.helper";

export const getDomainData = (
  language: Language,
  props: IDomain | ISubdomain,
  domainSlug?: string
) => {
  let translation: IDomainTranslation | ISubdomainTranslation | undefined;
  let link: string;
  let slug: string;
  if (isSubdomain(props)) {
    translation = props.subdomain_translation_set.find(
      (t) => t.language === language
    );
    slug = slugify(translation?.name ?? props.name).toLowerCase();
    link = `/${language}/${domainSlug}/${slug}`;
  } else {
    translation = props.domain_translation_set.find(
      (t) => t.language === language
    );
    slug = slugify(translation?.name ?? props.name).toLowerCase();
    link = `/${language}/${slug}`;
  }
  return [translation?.name ?? props.name, link, slug];
};
