import { NotFound } from "features/errors/notFound.page";
import { useLanguage } from "hooks/useLanguage.hook";
import { Outlet } from "react-router-dom";
import { isValidLanguage } from "types/enums/language.enum";

export const LanguageCheck = () => {
  const language = useLanguage();
  if (!isValidLanguage(language)) {
    return <NotFound />;
  }
  return <Outlet />;
};
