import { useQuery } from "@tanstack/react-query";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ArticleType } from "types/enums/articleType.enum";
import { StaleTime } from "utils/constants";
import { getArticle } from "../article/editArticle.query";
import { UpdateArticleEditor } from "../article/updateArticleEditor.page";
import { UpdateDecisionTreeEditor } from "../decision-tree/updateDecisionTreeEditor.page";

export const Editor = () => {
  const { articleId } = useParams<{ articleId?: string }>();
  const {
    isLoading,
    isError,
    data: article,
  } = useQuery({
    queryKey: ["article-edit", articleId],
    staleTime: StaleTime.None,
    queryFn: () => getArticle(Number(articleId)),
  });
  if (isLoading || isError) {
    return <Spinner></Spinner>;
  }
  if (article.type === ArticleType.decisionTree) {
    return <UpdateDecisionTreeEditor article={article} />;
  }
  return <UpdateArticleEditor article={article} />;
};
