import { BiImageAdd } from "react-icons/bi";

export const UploadImageButton = (props: {
  onClick: () => void;
  disabled: boolean;
}) => {
  return (
    <button
      className={"upload-image-btn"}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <BiImageAdd />
      Foto uploaden
    </button>
  );
};
