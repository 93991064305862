import { useSearchParams } from "react-router-dom";
import { ContentHeader } from "../layout/components/contentHeader.component";
import { ArticlePreview } from "./components/articlePreview.component";
import { useQuery } from "@tanstack/react-query";
import { getSearchResults } from "./search.query";
import { useLanguage } from "hooks/useLanguage.hook";

export const SearchPage = () => {
  let [searchParams] = useSearchParams();
  const language = useLanguage();
  const keywords = searchParams.get("keywords");
  const { data, isLoading } = useQuery({
    queryKey: ["search", keywords],
    queryFn: () => getSearchResults(keywords, language),
  });

  const articles = (data?.results ?? []).map((article) => (
    <ArticlePreview article={article} key={article.id} />
  ));
  return (
    <article>
      <ContentHeader
        isLoading={isLoading}
        title={`'${keywords}'`}
        dividerText={`${data?.result_count ?? 0} resultaten`}
      />
      <div className={"search-results"}>{articles}</div>
    </article>
  );
};
