import { ILoginError } from "types";
import { IArticleError } from "types/article.types";

export const isLoginError = (error: any): error is ILoginError => {
  return error?.response?.status === 400 && error.response.data !== undefined;
};

export const isArticleError = (
  error: any
): error is { response: { data: IArticleError } } => {
  return error?.response?.status === 400 && error.response.data !== undefined;
};
