import ReactQuill from "react-quill";

export function TextEditor(props: {
  chapterId: number;
  className?: string;
  setText(text: string, chapterId: number): void;
  value: string;
  isInvalid?: boolean;
  disabled?: boolean;
}) {
  console.log("rerendring with ", props.value);

  return (
    <ReactQuill
      theme="snow"
      className={`${props.className} ${
        props.isInvalid ? "text-editor-invalid" : ""
      } ${props.disabled ? "text-editor-disabled" : ""}`}
      value={props.value}
      onChange={(value) => props.setText(value, props.chapterId)}
    />
  );
}
