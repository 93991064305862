import { useLanguage } from "hooks/useLanguage.hook";
import { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { IChapter, IMedia, IShallowChapter, Loading } from "types";
import { Language } from "types/enums/language.enum";
import { getTranslation } from "utils/getTranslation.helper";
import { ArticleImage } from "./articleImage";
import { Chapter } from "./chapter";
import { TabNavigation } from "./tabNavigation";

export const ArticleBody = ({
  chapters,
  media,
  premiumChapters,
  isLoading,
}: Loading<{
  chapters: IChapter[];
  media: IMedia[];
  premiumChapters: IShallowChapter[];
}>) => {
  const refs = useRef<(HTMLHeadElement | null)[]>([]);
  const language = useLanguage();
  if (isLoading) {
    return (
      <div className={"article-body"}>
        <div className="article-content">
          <Skeleton containerClassName="chapters skeleton" count={3} />
          <Skeleton containerClassName="media skeleton" />
        </div>
      </div>
    );
  }
  const filteredChapters = chapters.filter((chapter) => getTranslation(chapter.chapter_translation_set, language));
  const filteredPremiumChapters = premiumChapters.filter((chapter) => getTranslation(chapter.chapter_translation_set, language));
  
  const titles = filteredChapters.map((chapter) =>
    mapChapterToTitle(chapter, language)
  );
  const premiumTitles = filteredPremiumChapters.map((chapter) =>
    mapChapterToTitle(chapter, language)
  );

  const chapterElements = filteredChapters.map((chapter, i) => (
    <Chapter
      key={chapter.title}
      {...chapter}
      ref={(el) => (refs.current[i] = el)}
    />
  ));

  const mediaElements = media
    .sort((a, b) => a.order - b.order)
    .map((image) => <ArticleImage key={image.caption} {...image} />);

  const onTabClick = (index: number) => {
    const element = refs.current[index];
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      var scrolledY = window.scrollY;

      if (scrolledY) {
        window.scroll(0, scrolledY - 1000);
      }
    }
  };
  return (
    <div className={"article-body"}>
      <TabNavigation
        onTabClick={onTabClick}
        isLoading={false}
        titles={titles}
        disabledTitles={premiumTitles}
      />
      <div className={"article-content"}>
        <div className={"chapters"}>{chapterElements}</div>
        <div className={"media"}>{mediaElements}</div>
      </div>
    </div>
  );
};

const mapChapterToTitle = (
  chapter: IChapter | IShallowChapter,
  language: Language
): string => {
  return (
    getTranslation(chapter.chapter_translation_set, language)?.title ??
    chapter.title
  );
};
