import { Outlet, useParams } from "react-router-dom";
import { Header } from "./components/header.component";
import { Sidebar } from "./components/sidebar.component";

import { useQuery } from "@tanstack/react-query";
import { getArticle } from "features/articles/article.query";
import { Expert } from "features/experts/components/sidebarExpert.component";
import { IoPeople } from "react-icons/io5";
import { t } from "i18next";

export const ArticleLayout = () => {
  const { articleSlug } = useParams<{
    domainSlug: string;
    subdomainSlug: string;
    articleSlug: string;
  }>();
  const { data: article } = useQuery({
    queryKey: ["article", articleSlug],
    queryFn: () => getArticle(articleSlug as string),
  });
  const experts = article?.article_author.map((articleAuthor) => (
    <Expert {...articleAuthor} key={articleAuthor.author.id} />
  ));
  return (
    <div>
      <Header />
      <div className={"app-cont"}>
        <Sidebar>
          <h2>
            <IoPeople /> {t("sidebar.authors")}
          </h2>
          {experts}
        </Sidebar>
        <div id={"content"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
