import { useQuery } from "@tanstack/react-query";
import { Divider } from "features/layout/components/divider.component";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { FilterType } from "types/enums/filterType.enum";
import { getArticles } from "./allArticles.query";
import { ArticleList } from "./components/articleList.component";

export const AllArticles = () => {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [filterValue, setFilterValue] = useState<FilterType>(
    (searchParams.get("filter") ?? "") as FilterType
  );

  const { data: articles, isSuccess } = useQuery({
    queryKey: ["articles", filterValue],
    queryFn: () => getArticles(filterValue),
  });

  const updateFilter = (value: FilterType) => {
    setSearchParams({ filter: value });
    setFilterValue(value);
  };

  return (
    <div className={"list-cont all-articles"}>
      <div className={"result-count-cont"}>
        <div className="filter-cont">
          <span>
            {isSuccess
              ? t("articles.articleWithCount", { count: articles.length })
              : ""}
          </span>
          <Form.Select
            onChange={(e) => updateFilter(e.target.value as FilterType)}
            value={filterValue}
            className="filter-select"
            name="filter"
          >
            <option value={FilterType.all}>{t("articles.filters.all")}</option>
            <option value={FilterType.published}>
              {t("articles.filters.published")}
            </option>
            <option value={FilterType.draft}>
              {t("articles.filters.draft")}
            </option>
          </Form.Select>
        </div>
        <Divider />
      </div>
      <ArticleList filter={filterValue} />
    </div>
  );
};
