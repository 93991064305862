import { getNameAndLink } from "features/admin/all-articles/helpers/getNameAndLink";
import { NodeType } from "features/admin/decision-tree/components/nodeModal.component";
import { useLanguage } from "hooks/useLanguage.hook";
import { useState } from "react";
import { BsNodePlus, BsNodePlusFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { IArticle, IDecisionNode } from "types";
import { Language } from "types/enums/language.enum";
import { getTranslation } from "utils/getTranslation.helper";

export const DecisionTreeContainer = (props: IArticle) => {
  return (
    <section className={"decision-tree-cont"}>
      <div className={"description"}>
        Klik op 1 van onderstaande blokken om de beslissingsboom te starten.
      </div>
      <div className="decision-tree">
        <DecisionTree nodes={props.decision_node_set} />
      </div>
    </section>
  );
};

const DecisionTree = (props: { nodes: IDecisionNode[] }) => {
  const [active, setActive] = useState<number | undefined>(undefined);

  const nodes = props.nodes.map((node) => {
    return (
      <DecisionNode
        key={node.id}
        node={node}
        active={active === node.id}
        initial={active === undefined}
        onClick={() => setActive(node.id)}
      />
    );
  });

  const childNodes =
    active !== undefined
      ? props.nodes.find((node) => node.id === active)?.children
      : null;

  return (
    <div>
      <div className="level">{nodes}</div>
      {childNodes && <DecisionTree nodes={childNodes} />}
    </div>
  );
};

const DecisionNode = (props: {
  active: boolean;
  initial: boolean;
  onClick: () => void;
  node: IDecisionNode;
}) => {
  const language = useLanguage();
  const isLeaf = props.node.children.length === 0;
  console.log(props.node);

  const className = isLeaf
    ? "leaf"
    : props.active
    ? "active"
    : props.initial
    ? ""
    : "non-active";
  const onClick = isLeaf ? () => {} : props.onClick;
  if (props.node.type === NodeType.article) {
    const link = getNameAndLink(props.node.article_details, language)[1];
    return (
      <Link className={`node`} to={link}>
        <div
          dangerouslySetInnerHTML={{ __html: getTitle(props.node, language) }}
        ></div>
      </Link>
    );
  }
  return (
    <div className={`node ${className}`} onClick={onClick}>
      {isLeaf ? null : props.active ? <BsNodePlusFill /> : <BsNodePlus />}
      <div
        dangerouslySetInnerHTML={{ __html: getTitle(props.node, language) }}
      ></div>
    </div>
  );
};

const getTitle = (
  node: IDecisionNode,
  language: Language,
  forceTitle: boolean = false
): string => {
  if (node.children.length === 0 && !forceTitle) {
    return node.interlinked_body ?? getTitle(node, language, true);
  }
  const translation = getTranslation(
    node.decision_node_translation_set,
    language
  );
  return translation?.title ?? "not found";
};
