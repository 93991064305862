import { Forbidden } from "features/errors/forbidden.page";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet } from "react-router-dom";
import { UserRole } from "types";
import { getToken } from "../getToken.helper";
import { Spinner } from "react-bootstrap";
import { getProfileIfToken } from "../getProfileIfToken.helper";
import { useQuery } from "@tanstack/react-query";

export const RequireAuth = (props: {
  roles?: UserRole[];
  children?: JSX.Element;
  redirect: boolean;
}) => {
  const { t } = useTranslation();
  const token = getToken();
  const { data: user, isLoading } = useQuery({
    queryKey: ["profile", token],
    queryFn: getProfileIfToken
  });

  // Allow if user is logged in and either `roles` is undefined or it contains the user's role
  if (isLoading) {
    return <Spinner/>;
  }
  
  if (
    user && (props.roles === undefined || props.roles.includes(user.role))
  ) {
    return props.children ?? <Outlet />;
  }
  return props.redirect ? (
    <Navigate to={t("fullRoutes.login")} />
  ) : (
    <Forbidden />
  );
};
