export const getProductName = (): string => {
  const product = process.env.REACT_APP_PRODUCT;
  if (product === "capripedia") {
    return "Capripedia";
  } else if (product === "ovipedia") {
    return "Ovipedia";
  } else if (product === "lactipedia") {
    return "Lactipedia";
  }
  throw new Error(`Invalid product name: ${product}`);
};
