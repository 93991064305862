import { useQuery } from "@tanstack/react-query";
import { getDomains } from "features/domains/domain.query";
import { useLanguage } from "hooks/useLanguage.hook";
import { Form, FormGroup } from "react-bootstrap";

export const DomainSelector = (props: {
  domain: string;
  setDomain(domain: string): void;
  isInvalid: boolean;
  disabled?: boolean;
}) => {
  const lang = useLanguage();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["domain"],
    queryFn: () => getDomains(lang),
  });

  const options = (data ?? [])
    .map((domain) => {
      return domain.subdomain_set.map((subdomain) => {
        return (
          <option
            key={`${domain.id}/${subdomain.id}`}
            value={`${domain.id}/${subdomain.id}`}
          >
            {domain.name} → {subdomain.name}
          </option>
        );
      });
    })
    .flat(1);
  return (
    <FormGroup className={"form-group"}>
      <Form.Select
        disabled={isLoading || isError || props.disabled}
        isInvalid={isError || props.isInvalid}
        value={props.domain}
        required={true}
        onChange={(e) => {
          props.setDomain(e.target.value);
        }}
      >
        <option value="-1" disabled>
          {isLoading
            ? "Laden..."
            : isError
            ? "Domeinen ophalen mislukt"
            : "Domein → Subdomein"}
        </option>
        {options}
      </Form.Select>
    </FormGroup>
  );
};
