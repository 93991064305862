import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { FormControl } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IArticle } from "types";
import { IArticleError } from "types/article.types";
import { LoadingButton } from "utils/components/button.component";
import { Divider } from "../../../layout/components/divider.component";
import { publishArticle } from "../../article/editArticle.query";

export const ArticleEditHeader = (props: {
  article?: IArticle;
  title: string;
  setTitle(newTitle: string): void;
  smallText?: string;
  error: IArticleError;
  isUpdating: boolean;
  setAlert?(data: { variant: "success" | "danger"; text: string }): void;
}) => {
  const published = props.article ? !!props.article?.date_published : undefined;
  const { articleId } = useParams<{ articleId?: string }>();
  const queryClient = useQueryClient();

  const { article, setTitle } = props;
  useEffect(() => {
    article && setTitle(article.title);
  }, [article, setTitle]);

  const isLoading = props.isUpdating;

  const { mutate, isLoading: isLoadingPublish } = useMutation({
    mutationFn: publishArticle,
    onSuccess: () => {
      queryClient.invalidateQueries(["article-edit"]);
    },
    onError: () => {
      props.setAlert &&
        props.setAlert({
          variant: "danger",
          text: "Er is iets misgegaan met het publishen/verbergen van het artikel.",
        });
    },
  });

  let errorMessage = undefined;
  if (props.error?.article_translation_set) {
    const translations = props.error.article_translation_set;
    if (
      translations.some(
        (translation) =>
          typeof translation !== "string" &&
          translation.title?.includes("This field may not be blank.")
      )
    )
      errorMessage = "Dit veld mag niet leeg zijn.";
    else if (
      translations.some((translation) => {
        return (
          typeof translation === "string" && translation.includes("bestaat al")
        );
      })
    ) {
      errorMessage = "Er bestaat reeds een artikel met deze titel.";
    }
  }
  const publish = () => {
    mutate({ id: Number(articleId), publish: !published });
  };
  return (
    <div className={"article-head"}>
      <div className={"title-save-cont"}>
        <div className="edit-title">
          <FormControl
            disabled={isLoading}
            required={true}
            onChange={(e) => props.setTitle(e.target.value)}
            value={props.title}
            type={"text"}
            isInvalid={!!errorMessage}
            placeholder={"Titel"}
          />
          {errorMessage && <span className="text-danger">{errorMessage}</span>}
        </div>
        <div className={"buttons-cont"}>
          <LoadingButton
            isLoading={isLoading}
            variant={"primary"}
            type={"submit"}
          >
            Opslaan
          </LoadingButton>
          {published !== undefined && (
            <LoadingButton
              isLoading={isLoadingPublish}
              variant={"danger"}
              onClick={publish}
            >
              {published ? "Verberg" : "Publiceer"}
            </LoadingButton>
          )}
        </div>
      </div>
      <Divider alignText={"left"}>{props.smallText}</Divider>
    </div>
  );
};
