import axios from "axios";
import { IArticle } from "types";

export const getArticle = async (slug: string): Promise<IArticle> => {
  const product = process.env.REACT_APP_PRODUCT;
  const fullSlug = product + "-" + slug;
  const { data } = await axios.get(`capripedia/article/${fullSlug}/`, {
    params: { product },
  });
  return data;
};
