import { Language } from "types/enums/language.enum";
import { fullRoutes } from "./helpers/fullRoutes.helper";
import { getProductName } from "utils";

const locale: Language = Language.EN;

const routes = {
  domains: `domains`,
  experts: `experts`,
  about: `about`,
  search: `search`,
  login: `login`,
  allArticles: `articles`,
  addArticle: `new-article`,
  newArticle: `new-articl/article`,
  newDecisionTree: `new-article/decision-tree`,
  profile: `profile`,
  logout: `logout`,
  subscription: `subscription`,
  buySubscription: `buy`,
  changeSubscription: `change`,
  askAI: `ask-ai`,
};

export const en = {
  locale,
  sidebar: {
    domains: "Domains",
    authors: "Article authors",
  },
  header: {
    search: "Search",
    allArticles: "All articles",
    addArticles: "Add article",
    login: "Login",
    profile: "Profile",
    logout: "Logout",
    dutch: "Dutch",
    english: "English",
    language: "Language",
  },
  homepage: {
    domains: "Domains",
    experts: "Experts",
    about: `About ${getProductName()}`,
    search: `Search in ${getProductName()}`,
  },
  domains: {
    subdomainWithCount_one: "({{count}} subdomain)",
    subdomainWithCount_other: "({{count}} sudomains)",
    domainWithCount_one: "({{count}} domains)",
    domainWithCount_other: "({{count}} domains)",
  },
  articles: {
    articleWithCount_one: "{{count}} article",
    articleWithCount_other: "{{count}} articles",
    inDraft:
      "This article is still in draft. It is only visible to admins, editors and experts.",
    createdAt: "Created at",
    paywall: {
      continueReading: "Continue reading?",
      getSubscription: `Buy a subscription for ${getProductName()} and get unlimited access to all articles.`,
      orderNow: "Order now",
      alreadyAnAccount:
        "Do you already have an account? Please <a>login</a> to continue reading.",
    },
    filters: {
      all: "All articles",
      published: "Published articles",
      draft: "Draft articles",
    },
    draft: "draft",
  },
  addArticle: {
    addArticle: "Add article",
    addDecisionTree: "Add decision tree",
  },
  experts: {
    contact: "Contact",
  },
  login: {
    email: "Email",
    password: "Password",
    forgot: "Forgot password?",
    stayLoggedIn: "Stay logged in",
    login: "Login",
    noAccount: "Not yet an account?",
    register: "Register",
    invalidCredentials: "Invalid email or password.",
    invalidEmail: "Invalid email",
    invalidPassword: "Invalid password",
  },
  profile: {
    myProfile: "Profile",
    personalData: "Personal data",
    firstName: "Firstname",
    lastName: "Lastname",
    email: "Email",
    telephone: "Telephone",
    addressData: "Address data",
    streetName: "Street name",
    number: "House number",
    country: "Country",
    postalCode: "Postal code",
    city: "City",
    save: "Save",
    loginData: "Login data",
    password: "Password",
    savePassword: "Save password",
    countries: {
      BE: "Belgium",
      NL: "Netherlands",
    },
    dataSavedSuccessfully: "Data saves successfully",
    emailInUse: "A user with this email address already exists.",
    passwordSavedSuccess: "Password updates successfully",
    passwordLength: "The password should contains at least 8 characters",
  },
  fullRoutes: fullRoutes(routes, locale),
  routes,
};
