import { Language } from "types/enums/language.enum";
import { routes } from "../nl";

type IRoutes = typeof routes;

export const fullRoutes = (
  routes: IRoutes,
  locale: Language
): IRoutes & { home: string } => {
  return {
    home: `/${locale}`,
    askAI: `/${locale}/${routes.askAI}`,
    domains: `/${locale}/${routes.domains}`,
    experts: `/${locale}/${routes.experts}`,
    about: `/${locale}/${routes.about}`,
    search: `/${locale}/${routes.search}`,
    login: `/${locale}/${routes.login}`,
    allArticles: `/${locale}/${routes.allArticles}`,
    addArticle: `/${locale}/${routes.addArticle}`,
    newArticle: `/${locale}/${routes.addArticle}/${routes.newArticle}`,
    newDecisionTree: `/${locale}/${routes.addArticle}/${routes.newDecisionTree}`,
    profile: `/${locale}/${routes.profile}`,
    logout: `/${locale}/${routes.logout}`,
    subscription: `/${locale}/${routes.subscription}`,
    buySubscription: `/${locale}/${routes.subscription}/${routes.buySubscription}`,
    changeSubscription: `/${locale}/${routes.subscription}/${routes.changeSubscription}`,
  };
};
