import { Outlet } from "react-router-dom";
import { Header } from "./components/header.component";
import { Sidebar } from "./components/sidebar.component";

export const MainLayout = () => {
  return (
    <div>
      <Header />
      <div className={"app-cont"}>
        <Sidebar />
        <div id={"content"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
