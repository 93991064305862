import { getProductName } from "utils";

export const NewSubscription = () => {
  return (
    <div className="no-sidebar-container">
      <h1>Abonnement nemen</h1>
      <hr />
      <p>
        Indien u geïnteresseerd bent in een abonnement op{" "}
        <b className="text-primary">{getProductName()}</b>, gelieve dan contact
        op te nemen met{" "}
        <a href="mailto:capripedia@quadrifolium.eu">
          capripedia@quadrifolium.eu
        </a>
      </p>
    </div>
  );
};
