import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Navigate } from "react-router-dom";

export const Logout = () => {
  sessionStorage.removeItem("token");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  console.log(axios.defaults.headers.common);
  const queryClient = useQueryClient();
  delete axios.defaults.headers.common["Authorization"];
  queryClient.clear();
  console.log(axios.defaults.headers.common);
  return <Navigate to="/nl/" />;
};
