import { Button } from "react-bootstrap";
export const Forbidden = () => {
  return (
    <div className="error-page">
      <h1>403</h1>
      <h2>U heeft geen toegang tot deze pagina.</h2>
      <Button href="/nl/">Naar homepagina</Button>
    </div>
  );
};
