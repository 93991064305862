import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { IMedia } from "types";
import { ArticleImage } from "../../../articles/components/articleImage";
import { getArticle } from "../editArticle.query";
import { NewImageModal } from "./newImageModal.component";
import { UpdateImageModel } from "./updateImageModal.component";
import { UploadImageButton } from "./uploadImageButton.component";

export const ImageUpload = () => {
  const { articleId } = useParams<{ articleId?: string }>();

  const { data: article, isLoading } = useQuery({
    queryKey: ["article-edit", articleId],
    queryFn: () => getArticle(Number(articleId)),
  });

  const [showNewImageModal, setShowNewImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<IMedia | undefined>();

  const editImage = (image: IMedia) => {
    setSelectedImage(image);
  };
  const addImage = () => {
    setSelectedImage(undefined);
    setShowNewImageModal(true);
  };
  const articleImages = article?.media_set.map((image) => (
    <ArticleImage
      disabled={isLoading}
      key={image.data}
      onClick={() => editImage(image)}
      {...image}
    />
  ));

  return (
    <div className={"image-upload"}>
      <NewImageModal
        show={showNewImageModal}
        close={() => setShowNewImageModal(false)}
      />
      {selectedImage && (
        <UpdateImageModel
          image={selectedImage}
          show={true}
          close={() => setSelectedImage(undefined)}
        />
      )}
      <UploadImageButton disabled={isLoading} onClick={addImage} />
      {articleImages}
    </div>
  );
};
