import { IconType } from "react-icons";
import { Link } from "react-router-dom";

export const HomepageButton = (props: {
  name: string;
  icon: IconType | JSX.Element;
  link: string;
}) => {
  const icon = typeof props.icon === "function" ? props.icon({}) : props.icon;
  return (
    <Link to={props.link} className={"gray-icon-button"}>
      <div className={"icon-cont"}>{icon}</div>
      <h3>{props.name}</h3>
    </Link>
  );
};
