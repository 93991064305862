import { useAuth } from "hooks/useAuth.hook";
import { GrEdit } from "react-icons/gr";
import { Link } from "react-router-dom";

export const EditButton = (props: { to: string }) => {
  const { user } = useAuth();
  if (["admin", "editor", "expert"].includes(user?.role ?? "")) {
    return (
      <Link to={props.to}>
        <GrEdit className="edit-article-icon" />
      </Link>
    );
  }
  return null;
};
