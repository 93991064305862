import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LanguageRedirect = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const browserLanguage = i18n.language.split("-")[0] || window.navigator.language.split('-')[0];
    const supportedLanguages = ['en', 'nl'];
    const language = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'nl';
    console.log(browserLanguage);
    navigate(`/${language}/`);
  }, [i18n, navigate]);

  return null;
};

export default LanguageRedirect;
