export enum Language {
  NL = "nl",
  EN = "en",
}

export const isValidLanguage = (
  language: string | undefined
): language is Language => {
  return language && (Object as any).values(Language).includes(language);
};
