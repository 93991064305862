import axios from "axios";
import { IAxiosResponse, IToken } from "types";

export const login = async (credentials: {
  email: string;
  password: string;
}): Promise<IAxiosResponse<IToken>> => {
  return axios.post("v1/api-token-auth/", {
    username: credentials.email,
    password: credentials.password,
  });
};
