import { SectionTitle } from "./sectionTitle.component";
import { Divider } from "./divider.component";
import { Loading } from "types/props.types";
import Skeleton from "react-loading-skeleton";

export const ContentHeader = (
  props: Loading<{
    title: string;
    smallText?: string;
    dividerText: string;
    icons?: JSX.Element;
  }>
) => {
  return (
    <div className={"article-head"}>
      <div>
        <SectionTitle
          title={props.title as string}
          smallText={props.smallText ?? ""}
          isLoading={props.isLoading}
        />
        {props.icons && <div className="icons">{props.icons}</div>}
        <Divider alignText={"right"}>
          {props.isLoading ? (
            <Skeleton containerClassName="skeleton" />
          ) : (
            props.dividerText
          )}
        </Divider>
      </div>
    </div>
  );
};
