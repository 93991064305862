import axios from "axios";
import { IUser } from "types";
import { DeepPartial } from "types/util.types";

export const getProfile = async (): Promise<IUser> => {
  const product = process.env.REACT_APP_PRODUCT;
  const { data } = await axios.get(`v1/profile/`, {
    params: { product: product },
  });
  localStorage.setItem("user", JSON.stringify(data));
  
  return data;
};

export const updateProfile = async (
  data: DeepPartial<IUser & { password: string }>
): Promise<void> => {
  return axios.patch(`v1/profile/`, data);
};
