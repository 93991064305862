import axios from "axios";
import { IDecisionTreeData } from "types/decisionTree.types";

export const createDecisionTree = (data: IDecisionTreeData) => {
  return axios.post("capripedia/article-edit/create_decision_tree/", data);
};

export const updateDecisionTree = (data: {
  id: number;
  body: IDecisionTreeData;
}) => {
  return axios.put(
    `capripedia/article-edit/${data.id}/update_decision_tree/`,
    data.body
  );
};
