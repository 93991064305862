import { useMutation, useQuery } from "@tanstack/react-query";
import { FormEvent, useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IUser } from "types";
import { DeepPartial } from "types/util.types";
import { getToken } from "utils/auth/getToken.helper";
import { ChangePasswordForm } from "./components/changePasswordForm.component";
import { getProfile, updateProfile } from "./profile.query";

export const Profile = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["profile", getToken()],
    queryFn: getProfile,
    onSuccess: (data) => {
      setFirstName(data.first_name ?? "");
      setLastName(data.last_name ?? "");
      setEmail(data.email ?? "");
      setTelephone(data.telephone ?? "");
      setStreetName(data.address?.street_name ?? "");
      setHouseNumber(data.address?.street_number ?? "");
      setPostalCode(data.address?.postal_code ?? "");
      setCountry(data.address?.country ?? "BE");
      setCity(data.address?.city ?? "");
    },
  });
  
  const [firstName, setFirstName] = useState(data?.first_name ?? "");
  const [lastName, setLastName] = useState(data?.last_name ?? "");
  const [email, setEmail] = useState(data?.email ?? "");
  const [telephone, setTelephone] = useState(data?.telephone ?? "");
  const [streetName, setStreetName] = useState(data?.address?.street_name ?? "");
  const [houseNumber, setHouseNumber] = useState(data?.address?.street_number ?? "");
  const [country, setCountry] = useState(data?.address?.country ?? "BE");
  const [postalCode, setPostalCode] = useState(data?.address?.postal_code ?? "");
  const [city, setCity] = useState(data?.address?.city ?? "");
  
  const { t } = useTranslation();
  // todo: fix broken submit
  const {
    mutate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    error,
  } = useMutation<unknown, any, DeepPartial<IUser>, unknown>({
    mutationFn: updateProfile,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({
      first_name: firstName ?? undefined,
      last_name: lastName ?? undefined,
      email: email ?? undefined,
      telephone: telephone ?? undefined,
      address: {
        street_name: streetName ?? undefined,
        street_number: houseNumber ?? undefined,
        postal_code: postalCode ?? undefined,
        country: country ?? undefined,
        city: city ?? undefined,
      },
    });
  };
  const errors = error?.response?.data;
  if (isLoading) {
    return <div>Loading...</div>;
  }
  const disableInputs = isLoading || isLoadingUpdate;
  return (
    <div className="profile no-sidebar-container">
      <h2>{t("profile.myProfile")}</h2>
      <hr />
      {isSuccessUpdate && (
        <Alert variant="success">{t("profile.dataSavedSuccessfully")}</Alert>
      )}

      <Form className="profile-edit" onSubmit={handleSubmit}>
        <div className="edit-group">
          <h3>{t("profile.personalData")}</h3>
          <FormGroup className="form-group">
            <FormLabel>
              {t("profile.firstName")}
              <span className="required">*</span>
            </FormLabel>
            <FormControl
              type="text"
              required={true}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={disableInputs}
              isInvalid={!!errors?.first_name}
            />
          </FormGroup>
          <FormGroup className="form-group">
            <FormLabel>
              {t("profile.lastName")}
              <span className="required">*</span>
            </FormLabel>
            <FormControl
              type="text"
              required={true}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={disableInputs}
              isInvalid={!!errors?.last_name}
            />
          </FormGroup>
          <FormGroup className="form-group">
            <FormLabel>
              {t("profile.email")}
              <span className="required">*</span>
            </FormLabel>
            <FormControl
              type="email"
              required={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={disableInputs}
              isInvalid={!!errors?.email}
            />
            {(errors?.email ?? []).includes(
              "user with this email address already exists."
            ) && (
              <span className="invalid-message">{t("profile.emailInUse")}</span>
            )}
          </FormGroup>
          <FormGroup className="form-group">
            <FormLabel>{t("profile.telephone")}</FormLabel>
            <FormControl
              required={false}
              type="text"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              disabled={disableInputs}
              isInvalid={!!errors?.telephone}
            />
          </FormGroup>
        </div>
        <div className="edit-group">
          <h3>{t("profile.addressData")}</h3>
          <FormGroup className="form-group street">
            <FormLabel>
              {t("profile.streetName")}
              <span className="required">*</span>
            </FormLabel>
            <FormControl
              type="text"
              value={streetName}
              onChange={(e) => setStreetName(e.target.value)}
              disabled={disableInputs}
              isInvalid={!!errors?.address?.street_name}
            />
          </FormGroup>
          <FormGroup className="form-group number">
            <FormLabel>
              {t("profile.number")}
              <span className="required">*</span>
            </FormLabel>
            <FormControl
              type="text"
              value={houseNumber}
              onChange={(e) => setHouseNumber(e.target.value)}
              disabled={disableInputs}
              isInvalid={!!errors?.address?.street_number}
            />
          </FormGroup>
          <FormGroup className="form-group country">
            <FormLabel>
              {t("profile.country")}
              <span className="required">*</span>
            </FormLabel>
            <Form.Select
              aria-label="Default select example"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              isInvalid={!!errors?.address?.country}
            >
              <option value="BE">{t("profile.countries.BE")}</option>
              <option value="NL">{t("profile.countries.NL")}</option>
            </Form.Select>
          </FormGroup>
          <FormGroup className="form-group postal">
            <FormLabel>
              {t("profile.postalCode")}
              <span className="required">*</span>
            </FormLabel>
            <FormControl
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              disabled={disableInputs}
              isInvalid={!!errors?.address?.postal_code}
            />
          </FormGroup>
          <FormGroup className="form-group city">
            <FormLabel>
              {t("profile.city")}
              <span className="required">*</span>
            </FormLabel>
            <FormControl
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              disabled={disableInputs}
              isInvalid={!!errors?.address?.city}
            />
          </FormGroup>
        </div>
        <div className="btn-cont">
          <Button variant="primary" type="submit">
            {t("profile.save")}
          </Button>
        </div>
      </Form>
      <hr />
      <ChangePasswordForm email={email} />
    </div>
  );
};
