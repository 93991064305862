import { ChangeEvent, useState } from "react";
import { FaImage, FaPaperPlane } from "react-icons/fa";
import TextareaAutosize from 'react-textarea-autosize';

export const AskAI = () => {
    const [text, setText] = useState("");

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === 'Enter' && !event.shiftKey && text.trim() !== '') {
                event.preventDefault();
                handleSubmit();
            }
        };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    };

    const handleSubmit = () => {
        console.log('Enter key pressed and there is text:', text);
        // Add your custom function logic here
    };

    return (
        <div className={"ask-ai-container"}>
            <div className={"coming-soon"}>
                Coming soon
            </div>
            <div className={"chatbox"}>
                <div className={"chat"}>
                    <div className={"message system"}>
                        <h3>Welcome to Capripedia AI!</h3>
                        Tell me about any questions you have regarding goats.
                    </div>
                    <div className={"message user"}>
                        My goats are constantly getting sick. What should I do?
                    </div>
                    <div className={"message system"}>
                        Can you give me some more information on the symptoms? Are they eating and drinking normally? Do you see any hairloss or skin issues?
                    </div>
                </div>
                <div className={"chat-input"}>
                <div className={"chat-input-container"}>
                    <input type="file" id="upload-input" style={{ display: "none" }} />
                    <label htmlFor="upload-input" className="icon upload-icon"><FaImage/></label>
                    <TextareaAutosize onChange={handleChange} onKeyDown={handleKeyDown} placeholder="Ask a question" />
                    <div onClick={handleSubmit} className="icon send-icon"><FaPaperPlane/></div>
                </div>
                </div>
            </div>
        </div>
    );
    };
