import { useQueryClient, useMutation } from "@tanstack/react-query";
import { useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormControl,
  FormGroup,
  Modal,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { LoadingButton } from "utils/components/button.component";
import { uploadFile } from "../editArticle.query";

export const NewImageModal = (props: { show: boolean; close: () => void }) => {
  const { articleId } = useParams<{ articleId?: string }>();
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError } = useMutation({
    mutationFn: uploadFile,
    onSuccess: () => {
      queryClient.invalidateQueries(["article-edit"]);
      props.close();
    },
  });

  const [caption, setCaption] = useState("");
  const [isFree, setIsFree] = useState(true);

  const [file, setFile] = useState<File | null>(null);
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] ?? null);
  };
  const handleUpload = async () => {
    if (!file) {
      return;
    }
    const data = {
      caption,
      is_free: isFree,
      article: Number(articleId),
      data: file,
      mime_type: file.type,
    };
    mutate(data);
    setFile(null);
    setIsFree(true);
    setCaption("");
  };

  return (
    <Modal show={props.show} onHide={props.close}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>Foto uploaden</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isError ? (
            <Alert variant="danger">Er is iets misgegaan.</Alert>
          ) : null}
          <div className={"modal-inputs"}>
            <FormGroup className={"half-width"}>
              <FormControl
                type={"text"}
                disabled={isLoading}
                placeholder={"Beschrijving"}
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </FormGroup>
            <FormGroup className={"half-width"}>
              <Form.Check
                disabled={isLoading}
                type={"checkbox"}
                label={"Gratis beschikbaar"}
                checked={isFree}
                onChange={() => setIsFree(!isFree)}
              />
            </FormGroup>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control
                type="file"
                disabled={isLoading}
                onChange={handleFileSelect}
                // defaultValue={props.image?.data}
                placeholder={"Afbeelding uploaden"}
              />
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            onClick={props.close}
            disabled={!file || !caption}
          >
            Annuleren
          </Button>
          <LoadingButton
            isLoading={isLoading}
            variant="primary"
            disabled={!file || !caption}
            onClick={handleUpload}
          >
            Opslaan
          </LoadingButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
