import { HomepageButton } from "features/home/components/homepageButton";
import { useTranslation } from "react-i18next";
import { IoDocuments } from "react-icons/io5";
import { DecisionTreeIcon } from "utils/components/decisionTreeIcon.component";

export const AddArticle = () => {
  const { t } = useTranslation();
  return (
    <div className="article-type">
      <HomepageButton
        name={t("addArticle.addArticle")}
        icon={IoDocuments}
        link={t("fullRoutes.newArticle")}
      />
      <HomepageButton
        name={t("addArticle.addDecisionTree")}
        icon={<DecisionTreeIcon />}
        link={t("fullRoutes.newDecisionTree")}
      />
    </div>
  );
};
