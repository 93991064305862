import { useQuery } from "@tanstack/react-query";
import { getExperts } from "features/experts/experts.query";
import { useLanguage } from "hooks/useLanguage.hook";
import { Form, FormGroup } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { IoMdRemoveCircle } from "react-icons/io";
import { IArticleError } from "types/article.types";

export const ExpertPicker = (props: {
  selectedExperts: IPickedExpert[];
  setSelectedExperts(
    experts: IPickedExpert[] | ((experts: IPickedExpert[]) => IPickedExpert[])
  ): void;
  errors: IArticleError;
  disabled?: boolean;
}) => {
  const language = useLanguage();
  const {
    data: experts,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["experts"],
    queryFn: () => getExperts(language),
    cacheTime: 5 * 60 * 1000,
  });

  const selectedIds = props.selectedExperts.map((expert) => expert.id);
  const expertOptions = (experts ?? [])
    .filter((expert) => !selectedIds.includes(expert.id))
    .map((expert) => (
      <option value={expert.id} key={expert.id}>
        {expert.user.first_name} {expert.user.last_name}
      </option>
    ));

  const moveExpert = (currentIndex: number, up: boolean) => {
    const newExperts = [...props.selectedExperts];

    if (
      (currentIndex === 0 && up) ||
      (currentIndex === props.selectedExperts.length - 1 && !up)
    ) {
      return newExperts;
    }
    const movement = up ? -1 : 1;
    const temp = newExperts[currentIndex + movement];
    newExperts[currentIndex + movement] = newExperts[currentIndex];
    newExperts[currentIndex] = temp;
    props.setSelectedExperts(newExperts);
  };
  const expertItems = props.selectedExperts.map((expert, i) => (
    <Expert
      key={expert.id}
      name={expert.name}
      first={i === 0}
      last={i === props.selectedExperts.length - 1}
      move={(up) => moveExpert(i, up)}
      onDelete={() => {
        props.setSelectedExperts((experts) =>
          experts.filter((e) => e.id !== expert.id)
        );
      }}
    />
  ));

  const addExpert = (expertId: string) => {
    const expert = (experts ?? []).find((e) => e.id === Number(expertId));
    if (expert === undefined) {
      return;
    }
    props.setSelectedExperts((experts) =>
      experts.concat([
        {
          name: `${expert.user.first_name} ${expert.user.last_name}`,
          id: expert.id,
        },
      ])
    );
  };
  return (
    <div className="expert-picker">
      <FormGroup className={"form-group"}>
        <Form.Select
          isInvalid={isError || !!props.errors.article_author}
          disabled={isLoading || isError || props.disabled}
          value=""
          onChange={(e) => addExpert(e.target.value)}
        >
          <option value="" disabled>
            {isLoading ? "Laden..." : "Kies een expert"}
          </option>
          {expertOptions}
        </Form.Select>
      </FormGroup>
      <ul className="list">{expertItems}</ul>
    </div>
  );
};

const Expert = (props: {
  name: string;
  first: boolean;
  last: boolean;
  move(up: boolean): void;
  onDelete(): void;
}) => {
  return (
    <li>
      <div className="labels">
        <IoMdRemoveCircle className="text-danger" onClick={props.onDelete} />
        {props.name}
      </div>
      <div className="move-icons">
        <FaChevronUp
          className={props.first ? "disabled" : ""}
          onClick={() => props.move(true)}
        />
        <FaChevronDown
          className={props.last ? "disabled" : ""}
          onClick={() => props.move(false)}
        />
      </div>
    </li>
  );
};

export interface IPickedExpert {
  id: number;
  name: string;
}
