import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import { getToken } from "utils/auth/getToken.helper";
import "./i18n/i18n";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
console.log("base url", process.env.REACT_APP_BASE_URL);

const token = getToken();
if (token !== undefined) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;
}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
