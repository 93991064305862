import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { NotFound } from "features/errors/notFound.page";
import { ServerError } from "features/errors/serverError.page";
import { useLanguage } from "hooks/useLanguage.hook";
import { t } from "i18next";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IArticle } from "types";
import { getTranslation } from "utils/getTranslation.helper";
import { ContentHeader } from "../layout/components/contentHeader.component";
import { getArticle } from "./article.query";
import { ArticleBody } from "./components/articleBody";
import { DecisionTreeContainer } from "./components/decisionTreeContainer";
import { EditButton } from "./components/editButton.component";
import { Paywall } from "./components/paywall";

export const Article = () => {
  const { articleSlug } = useParams<{
    domainSlug: string;
    subdomainSlug: string;
    articleSlug: string;
  }>();

  const {
    data: article,
    isLoading,
    isFetching,
    isError,
    error,
  } = useQuery<IArticle, AxiosError, IArticle, (string | undefined)[]>({
    queryKey: ["article", articleSlug],
    queryFn: () => getArticle(articleSlug as string),
  });

  const language = useLanguage();
  if (isLoading || isFetching) {
    return (
      <article>
        <ContentHeader isLoading={true} />
        <ArticleBody isLoading={true} />
      </article>
    );
  } else if (isError) {
    if (error?.response?.status === 404) {
      return <NotFound />;
    }
    return <ServerError />;
  }

  const translation = getTranslation(article.article_translation_set, language);
  if (translation === undefined) {
    return <NotFound />;
  }

  const createdAt = new Date(article.date_created);
  const linkNames = article.link_name_set
    .filter((linkName) => linkName.language === language)
    .map((linkName) => linkName.link_name)
    .join(", ");

  const icon = (
    <EditButton to={`${t("fullRoutes.allArticles")}/${article.id}`} />
  );

  return (
    <article>
      {article.date_published === null ? (
        <Alert variant="warning" className="article-warning">
          {t("articles.inDraft")}
        </Alert>
      ) : null}
      <ContentHeader
        isLoading={isLoading}
        title={translation.title}
        smallText={linkNames !== "" ? `(${linkNames})` : undefined}
        dividerText={`${t(
          "articles.createdAt"
        )} ${createdAt.toLocaleDateString()}`}
        icons={icon}
      />
      {article.type === "article" ? (
        <ArticleBody
          isLoading={false}
          chapters={article.chapter_set}
          media={article.media_set}
          premiumChapters={article.premium_chapters ?? []}
        />
      ) : (
        <DecisionTreeContainer {...article} />
      )}
      {(article.premium_chapters ?? []).length > 0 ? <Paywall /> : null}
    </article>
  );
};
