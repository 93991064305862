import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  Modal,
  Alert,
  FormGroup,
  FormControl,
  Button,
  Form,
} from "react-bootstrap";
import { IMedia } from "types";
import { LoadingButton } from "utils/components/button.component";
import { updateFile, deleteFile } from "../../article/editArticle.query";

export const UpdateImageModel = (props: {
  show: boolean;
  close: () => void;
  image: IMedia;
}) => {
  const {
    mutate: updateImage,
    isLoading: isUpdating,
    isError: isErrorUpdate,
  } = useMutation({
    mutationFn: updateFile,
    onSuccess: () => {
      queryClient.invalidateQueries(["article-edit"]);
      props.close();
    },
  });
  const queryClient = useQueryClient();
  const {
    mutate: deleteImage,
    isLoading: isDeleting,
    isError: isErrorDelete,
  } = useMutation({
    mutationFn: deleteFile,
    onSuccess: () => {
      queryClient.invalidateQueries(["article-edit"]);
      props.close();
    },
  });

  const [caption, setCaption] = useState(props.image?.caption ?? "");
  const [isFree, setIsFree] = useState(props.image?.is_free ?? true);

  const handleUpload = async () => {
    const data = {
      caption,
      is_free: isFree,
    };
    updateImage({ file: data, id: props.image.id });
  };

  const handleDelete = async () => {
    deleteImage(props.image.id);
  };

  const isLoading = isUpdating || isDeleting;
  return (
    <Modal show={props.show} onHide={props.close}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>Foto uploaden</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isErrorDelete || isErrorUpdate ? (
            <Alert variant="danger">Er is iets misgegaan.</Alert>
          ) : null}
          <div className={"modal-inputs"}>
            <FormGroup className={"half-width"}>
              <FormControl
                type={"text"}
                disabled={isLoading}
                placeholder={"Beschrijving"}
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </FormGroup>
            <FormGroup className={"half-width"}>
              <Form.Check
                disabled={isLoading}
                type={"checkbox"}
                label={"Gratis beschikbaar"}
                checked={isFree}
                onChange={(e) => setIsFree(!isFree)}
              />
            </FormGroup>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>
            Verwijderen
          </Button>
          <LoadingButton
            isLoading={isLoading}
            disabled={!caption}
            variant="primary"
            onClick={handleUpload}
          >
            Opslaan
          </LoadingButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
