import { ReactNode } from "react";

export const Divider = (props: {
  children?: ReactNode;
  alignText?: "left" | "right";
  borderPosition?: "top" | "bottom";
}) => {
  return (
    <div
      className={`divider align-${props.alignText ??
        "left"} border-${props.borderPosition ?? "bottom"}`}
    >
      {props.children ?? ""}
    </div>
  );
};
