import { IMedia } from "types";

export const ArticleImage = (
  props: IMedia & {
    onClick?: () => void;
    disabled?: boolean;
  }
) => {
  return (
    <div className={`article-image ${props.disabled ? "disabled" : ""}`}>
      <img src={props.data} alt={props.caption} onClick={props?.onClick} />
    </div>
  );
};
