import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AllArticles } from "features/admin/all-articles/allArticles.page";
import { AddArticle } from "features/admin/article/addArticle.page";
import { NewArticleEditor } from "features/admin/article/newArticleEditor.page";
import { NewDecisionTreeEditor } from "features/admin/decision-tree/newDecisionTreeEditor.page";
import { Editor } from "features/admin/editor/editor.page";
import { Login } from "features/auth/login.page";
import { Logout } from "features/auth/logout.page";
import { Profile } from "features/auth/profile.page";
import { Subdomains } from "features/domains/subdomain.page";
import { Experts } from "features/experts/experts.page";
import { ArticleLayout } from "features/layout/article.layout";
import { LanguageCheck } from "features/layout/languageCheck.layout";
import { ChangeSubscription } from "features/subscriptions/changeSubscription.page";
import { NewSubscription } from "features/subscriptions/newSubscription.page";
import { useTranslation } from "react-i18next";
import { RequireAuth } from "utils/auth/components/requireAuth.component";
import { StaffRoles } from "utils/auth/roles.constants";
import { Article } from "./features/articles/article.page";
import { Domains } from "./features/domains/domain.page";
import { Homepage } from "./features/home/home.page";
import { HeaderLayout } from "./features/layout/header.layout";
import { MainLayout } from "./features/layout/main.layout";
import { SearchPage } from "./features/search/search.page";
import "./scss/app.scss";
import { StaleTime } from "utils/constants";
import { Helmet } from "react-helmet";
import { getProductName } from "utils";
import LanguageRedirect from "features/language/languageRedirect";
import { AskAI } from "features/ai/ai.page";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: 0, staleTime: StaleTime.Normal } },
  });
  const { t } = useTranslation();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="" element={<LanguageRedirect />} />
        <Route path=":language" element={<LanguageCheck />}>
          <Route path="" element={<HeaderLayout hideSearch={true} />}>
            <Route path="" element={<Homepage />} />
          </Route>
          <Route path={t("routes.search")} element={<MainLayout />}>
            <Route path="" element={<SearchPage />} />
          </Route>
          <Route path="" element={<MainLayout />}>
            <Route path={t("routes.domains")} element={<Domains />} />
            <Route path={":domainSlug"} element={<Domains />} />
            <Route path=":domainSlug/:subdomainSlug" element={<Subdomains />} />
          </Route>

          <Route path="" element={<ArticleLayout />}>
            <Route
              path=":domainSlug/:subdomainSlug/:articleSlug"
              element={<Article />}
            />
          </Route>
          <Route element={<HeaderLayout />}>
            <Route path={t("routes.experts")} element={<Experts />} />
            <Route
              path={t("routes.profile")}
              element={
                <RequireAuth redirect={true}>
                  <Profile />
                </RequireAuth>
              }
            />
            

            <Route
              element={<RequireAuth roles={StaffRoles} redirect={false} />}
            >
              <Route path={t("routes.allArticles")}>
                <Route path="" element={<AllArticles />} />
                <Route path=":articleId" element={<Editor />} />
              </Route>
              <Route path={t("routes.addArticle")}>
                <Route path="" element={<AddArticle />} />
                <Route
                  path={t("routes.newArticle")}
                  element={<NewArticleEditor />}
                />
                <Route
                  path={t("routes.newDecisionTree")}
                  element={<NewDecisionTreeEditor />}
                />
              </Route>
            </Route>

            <Route path={t("routes.subscription")}>
              <Route
                path=""
                element={<Navigate to={t("fullRoutes.buySubscription")} />}
              />
              <Route
                path={t("routes.buySubscription")}
                element={<NewSubscription />}
              />
              <Route
                path={t("routes.changeSubscription")}
                element={<ChangeSubscription />}
              />
            </Route>
          </Route>
          <Route element={<HeaderLayout hideSearch={true} />}>
            <Route
              path={t("routes.askAI")}
              element={<AskAI />}
            />
            </Route>
          <Route path={t("routes.login")} element={<Login />} />
          <Route path={t("routes.logout")} element={<Logout />} />
        </Route>
      </Route>
    )
  );
  const product = process.env.REACT_APP_PRODUCT;
  return (
    <div className="app">
      <Helmet>
        <title>{getProductName()}</title>
        <link rel="icon" href={`/${product}-favicon.ico`} />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
