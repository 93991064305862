import axios from "axios";
import { IDomain } from "types";
import { Language } from "types/enums/language.enum";

export const getDomains = async (language: Language): Promise<IDomain[]> => {
  const product = process.env.REACT_APP_PRODUCT;
  const { data } = (await axios.get(`/capripedia/domain/`, {
    params: {
      lan: language,
      pedia_type: product,
    },
  })) as { data: IDomain[] };
  return data
    .filter(
      (domain) =>
        domain.domain_translation_set.find(
          (translation) => translation.language === language
        ) !== undefined
    )
    .map((domain) => {
      const subdomain_set = domain.subdomain_set
        .filter(
          (subdomain) =>
            subdomain.subdomain_translation_set.find(
              (translation) => translation.language === language
            ) !== undefined
        )
        .map((subdomain) => {
          const article_set = subdomain.article_set.filter(
            (article) =>
              article.article_translation_set.find(
                (translation) => translation.language === language
              ) !== undefined
          );

          return { ...subdomain, article_set };
        });
      return { ...domain, subdomain_set };
    });
};
