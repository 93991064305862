import { useQuery } from "@tanstack/react-query";
import { getArticles } from "features/admin/all-articles/allArticles.query";
import { useLanguage } from "hooks/useLanguage.hook";
import { useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Modal,
} from "react-bootstrap";
import { IShallowArticle } from "types";
import { IDecisionNodeData } from "types/decisionTree.types";
import { FilterType } from "types/enums/filterType.enum";
import { Language } from "types/enums/language.enum";
import { getTranslation } from "utils/getTranslation.helper";
import update from "immutability-helper";
export enum NodeType {
  article = "article",
  node = "node",
}
export type INode = {
  name: string;
  article?: number;
  type: NodeType;
};
export const NodeModal = (props: {
  show: boolean;
  close(): void;
  saveNode(node: Omit<IDecisionNodeData, "order"> | undefined): void;
  node?: IDecisionNodeData;
}) => {
  const language = useLanguage();
  const [type, setType] = useState(props.node?.type ?? NodeType.article);
  const name =
    getTranslation(props.node?.decision_node_translation_set, language)
      ?.title ?? "";

  const [title, setTitle] = useState(name);
  const [article, setArticle] = useState(
    props.node?.type === NodeType.article ? props.node.article ?? -1 : -1
  );
  const [submitted, setSubmitted] = useState(!!props.node);
  const { data: articles, isError } = useQuery({
    queryKey: ["articles", FilterType.published],
    queryFn: () => getArticles(FilterType.published),
  });

  const articleOptions = (articles ?? []).map((article) => (
    <ArticleOption {...article} key={article.id} />
  ));

  const save = () => {
    if (!title || (type === NodeType.article && article === -1)) {
      setSubmitted(true);
      return;
    }
    if (props.node) {
      const newNode = update(props.node, {
        decision_node_translation_set: { $set: [{ language, title }] },
      });
      if (type === NodeType.article) {
        newNode.article = article;
      }
      props.saveNode(newNode);
      return props.close();
    }
    props.saveNode(getNodeData(type, title, article, language));
    return props.close();
  };

  return (
    <Modal show={props.show} onHide={props.close}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>Foto uploaden</Modal.Title>
        </Modal.Header>

        <Modal.Body className="node-modal">
          <FormGroup className="form-group">
            <FormLabel>Titel</FormLabel>
            <FormControl
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              isInvalid={submitted && !title}
              type={"text"}
            />
          </FormGroup>
          {!props.node?.type && (
            <FormGroup className="form-group">
              <FormLabel>Type</FormLabel>
              <Form.Select
                onChange={(e) => {
                  setType(e.target.value as NodeType);
                }}
                value={type}
              >
                <option value={NodeType.article}>Article</option>
                <option value={NodeType.node}>Node</option>
              </Form.Select>
            </FormGroup>
          )}
          {type === NodeType.article && (
            <FormGroup className="form-group">
              <FormLabel>Artikel</FormLabel>
              <FormSelect
                value={article}
                onChange={(e) => {
                  setArticle(Number(e.target.value));
                }}
                isInvalid={isError || (submitted && article === -1)}
              >
                <option value="-1" disabled>
                  Kies een artikel
                </option>
                {articleOptions}
              </FormSelect>
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          {props.node && (
            <Button
              variant="danger"
              onClick={() =>
                props.saveNode(update(props.node, { deleted: { $set: true } }))
              }
            >
              Verwijderen
            </Button>
          )}
          <Button variant="primary" onClick={save}>
            Opslaan
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const getNodeData = (
  type: NodeType,
  title: string,
  article: number | undefined,
  language: Language
): Omit<IDecisionNodeData, "order"> => {
  if (type === NodeType.article) {
    return {
      type: NodeType.article,
      article: article as number,
      decision_node_translation_set: [{ language, title }],
    };
  } else {
    return {
      type: NodeType.node,
      decision_node_translation_set: [{ language, title }],
      children: [],
      article: null,
    };
  }
};

const ArticleOption = (article: IShallowArticle) => {
  const articleName = article.title;
  const subdomain = article.subdomain.name;
  const domain = article.subdomain.domain.name;
  return (
    <option
      key={article.id}
      value={article.id}
    >{`${articleName} (${subdomain} → ${domain})`}</option>
  );
};
