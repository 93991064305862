import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { IArticleError } from "types/article.types";
import { ArticleType } from "types/enums/articleType.enum";
import { DomainSelector } from "./domainSelector.component";
import { IPickedExpert, ExpertPicker } from "./expertPicker.component";

type propTypes = {
  disabled: boolean;
  domain: string;
  invalidDomain: boolean;
  setDomain(newDomain: string): void;
  linkWords: string;
  setLinkWords(newLinkWords: string): void;
  selectedExperts: IPickedExpert[];
  setSelectedExperts(newExperts: IPickedExpert[]): void;
  errors: IArticleError;
} & (
  | {
      type: ArticleType.decisionTree;
      description: string;
      setDescription(newDescription: string): void;
    }
  | { type: ArticleType.article }
);

export const MetaDataEditor = (props: propTypes) => {
  return (
    <div className="metadata">
      <div className={"input-cont"}>
        <DomainSelector
          disabled={props.disabled}
          domain={props.domain}
          setDomain={props.setDomain}
          isInvalid={props.invalidDomain}
        />

        <FormGroup className={"form-group"}>
          <FormLabel>Afkortingen, synoniemen:</FormLabel>
          <FormControl
            disabled={props.disabled}
            type={"text"}
            value={props.linkWords}
            isInvalid={!!props.errors.link_name_set}
            onChange={(e) => props.setLinkWords(e.target.value)}
          />
        </FormGroup>
        {props.type === ArticleType.decisionTree && (
          <FormGroup className={"form-group"}>
            <FormLabel>Beschrijving:</FormLabel>
            <FormControl
              disabled={props.disabled}
              as="textarea"
              rows={3}
              value={props.description}
              isInvalid={!!props.errors.description}
              onChange={(e) => props.setDescription(e.target.value)}
            />
          </FormGroup>
        )}
      </div>
      <div className="input-cont expert-picker-cont">
        <FormLabel>Auteurs:</FormLabel>
        <ExpertPicker
          disabled={props.disabled}
          errors={props.errors}
          selectedExperts={props.selectedExperts}
          setSelectedExperts={props.setSelectedExperts}
        />
      </div>
    </div>
  );
};
