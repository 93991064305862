import { IShallowArticle } from "types";
import { Language } from "types/enums/language.enum";
import { removePediaType } from "utils";
import { getTranslation } from "utils/getTranslation.helper";

export const getNameAndLink = (
  article: IShallowArticle,
  language: Language
): [string, string] => {
  const translation = getTranslation(article.article_translation_set, language);
  const subdomainSlug = article.subdomain.subdomain_language_slug;
  const domainSlug = article.subdomain.domain.domain_language_slug;

  const link = `/${language}/${domainSlug}/${subdomainSlug}/${removePediaType(
    article.slug
  )}`;
  return [translation?.title ?? article.title, link];
};
