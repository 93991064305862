import slugify from "slugify";
import { IDomain } from "types";
import { Language } from "types/enums/language.enum";
import { getDomainBySlug } from "./getDomainBySlug.helper";

export const getSubdomainBySlug = (
  data: IDomain[],
  domainSlug: string,
  subdomainSlug: string,
  language: Language
) => {
  const domain = getDomainBySlug(data, domainSlug, language);

  return domain?.subdomain_set.find((subdomain) => {
    const translation = subdomain.subdomain_translation_set.find(
      (t) => t.language === language
    );
    const name = translation?.name ?? subdomain.name;
    return slugify(name).toLowerCase() === subdomainSlug;
  });
};
