import { useQuery } from "@tanstack/react-query";
import { ServerError } from "features/errors/serverError.page";
import { useLanguage } from "hooks/useLanguage.hook";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { IExpert } from "types";
import { getTranslation } from "utils/getTranslation.helper";
import wim from "../../assets/images/wim.jpeg";
import { getExperts } from "./experts.query";

export const Experts = () => {
  const language = useLanguage();
  const {
    data: experts,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["experts"],
    queryFn: () => getExperts(language),
    cacheTime: 5 * 60 * 1000,
  });
  if (isLoading) {
    return (
      <div className="expert-list">
        <ExpertCard isLoading={true} />
      </div>
    );
  }
  if (isError) {
    return <ServerError />;
  }
  return (
    <div className="expert-list">
      {experts.map((expert) => (
        <ExpertCard {...expert} key={expert.id} isLoading={false} />
      ))}
    </div>
  );
};

const ExpertCard = (
  props: { isLoading: true } | (IExpert & { isLoading: false })
) => {
  const language = useLanguage();
  const { t } = useTranslation();
  if (props.isLoading) {
    return (
      <div className="expert">
        <Skeleton containerClassName="expert-head" className="h-3" />
        <Skeleton containerClassName="expert-head" className="h-2" />
        <Skeleton containerClassName="expert-description" />
        <Skeleton containerClassName="expert-description" />
        <Skeleton containerClassName="expert-description" />
      </div>
    );
  }

  const translation = getTranslation(props.expert_translation_set, language);
  return (
    <div className="expert">
      <div className="expert-head">
        <div className="expert-image">
          <img src={wim} alt="todo" />
        </div>
        <div className="expert-details">
          <h3>
            {props.user.first_name} {props.user.last_name}
          </h3>
          <h4>{translation?.job_title}</h4>
        </div>
      </div>
      <div className="expert-description">{translation?.description}</div>
      <div className="expert-contact">
        <Link to={props.website}>{props.website}</Link>
        <a href={`mailto:${props.user.email}`}>{props.user.email}</a>
      </div>
      <a href={`mailto:${props.user.email}`} className="expert-footer">
        {t("experts.contact")} {props.user.first_name}
      </a>
    </div>
  );
};
