import { useQuery } from "@tanstack/react-query";
import { NotFound } from "features/errors/notFound.page";
import { ServerError } from "features/errors/serverError.page";
import { useLanguage } from "hooks/useLanguage.hook";
import { t } from "i18next";
import { useParams } from "react-router-dom";
import { getSubdomainBySlug } from "utils/getSubdomainBySlug.helper";
import { getTranslation } from "utils/getTranslation.helper";
import { Divider } from "../layout/components/divider.component";
import { SectionTitle } from "../layout/components/sectionTitle.component";
import { SubdomainList } from "./components/subdomainList";
import { getDomains } from "./domain.query";

export const Subdomains = () => {
  const { domainSlug, subdomainSlug } = useParams<{
    domainSlug: string;
    subdomainSlug: string;
  }>();
  const lang = useLanguage();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["domain"],
    queryFn: () => getDomains(lang),
    cacheTime: 5 * 60 * 1000,
  });

  const language = useLanguage();
  if (domainSlug === undefined || subdomainSlug === undefined) {
    return <NotFound />;
  }

  if (isError) {
    return <ServerError />;
  }
  if (isLoading) {
    return (
      <div className={"list-cont"}>
        <div className={"result-count-cont"}>
          <SectionTitle isLoading={true} />
          <Divider />
        </div>
        <SubdomainList isLoading={true} />
      </div>
    );
  }
  const subdomain =
    data && getSubdomainBySlug(data, domainSlug, subdomainSlug, language);

  if (subdomain === undefined) {
    return <NotFound />;
  }
  let title: string | undefined;

  const translation = getTranslation(
    subdomain.subdomain_translation_set,
    language
  );
  title = translation?.name ?? subdomain.name;
  const resultCount = subdomain.article_set.length;
  return (
    <div className={"list-cont"}>
      <div className={"result-count-cont"}>
        <SectionTitle
          title={title ?? ""}
          isLoading={isLoading}
          smallText={`(${t("articles.articleWithCount", {
            count: resultCount,
          })})`}
        />
        <Divider />
      </div>
      <SubdomainList
        {...subdomain}
        isLoading={isLoading}
        domainSlug={domainSlug}
        subdomainSlug={subdomainSlug}
      />
    </div>
  );
};
