import { useQuery } from "@tanstack/react-query";
import i18n from "i18n/i18n";
import { Container, Form, Nav, Navbar, NavDropdown, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form as RouterForm, Link } from "react-router-dom";
import { getProductName } from "utils";
import { getProfileIfToken } from "utils/auth/getProfileIfToken.helper";
import { getToken } from "utils/auth/getToken.helper";
function changeLanguage(language: "en"| "nl") {
  window.location.href = `/${language}`;
}
export const Header = (props: { hideSearch?: boolean }) => {
  const { t } = useTranslation();
  let language = i18n.language.toUpperCase().split("-")[0];
  if (["EN", "NL"].includes(language) === false) {
    language = "EN";
  }
  return (
    <Navbar bg={"primary"} variant={"dark"} className={"header"} expand={true}>
      <Container fluid>
        <Navbar.Brand as={Link} to={t("fullRoutes.home")}>
          {getProductName()}
        </Navbar.Brand>
        <Navbar.Collapse
          id="navbarScroll"
          className={props.hideSearch ? "hide-search" : ""}
        >
          {props.hideSearch || (
            <Form
              as={RouterForm}
              method="get"
              action={t("fullRoutes.search")}
              className="mx-auto search-bar d-lg-flex d-none"
            >
              <Form.Control
                type="search"
                name="keywords"
                placeholder={t("header.search")}
                aria-label={t("header.search")}
              />
            </Form>
          )}
          <ProfileButton />
        </Navbar.Collapse>
        <NavDropdown
          className="language-select"
          title={language}
          id="language-dropdown"
          align="end"
        >
          <NavDropdown.Item onClick={() => changeLanguage("en")}>
            EN
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => changeLanguage("nl")}>
            NL
          </NavDropdown.Item>
        </NavDropdown>
      </Container>
    </Navbar>
  );
};

const ProfileButton = () => {
  const { data: user, isLoading } = useQuery({
    queryKey: ["profile", getToken()],
    queryFn: getProfileIfToken,
  });
  
  const { t } = useTranslation();
  const editorTabsData = [
    { to: t("fullRoutes.allArticles"), name: t("header.allArticles") },
    { to: t("fullRoutes.addArticle"), name: t("header.addArticles") },
  ];

  if (user == null) {
    return (
      <Nav.Item>
        <Nav.Link as={Link} to={t("fullRoutes.login")}>
          {isLoading ? <Spinner size="sm"/> : t("header.login")}
        </Nav.Link>
      </Nav.Item>
    );
  }
  const editRights = ["admin", "editor", "expert"].includes(user.role);
  const editorTabs = editRights
    ? editorTabsData.map((tabData) => {
        return (
          <NavDropdown.Item to={tabData.to} as={Link} key={tabData.name}>
            {tabData.name}
          </NavDropdown.Item>
        );
      })
    : null;
  return (
    <NavDropdown
      id="profile-button"
      title={`${user.first_name} ${user.last_name}`}
      className="nav-item"
      align="end"
    >
      <NavDropdown.Item to={t("fullRoutes.profile")} as={Link}>
        {t("header.profile")}
      </NavDropdown.Item>
      {editorTabs}
      <NavDropdown.Item to={t("fullRoutes.logout")} as={Link}>
        {t("header.logout")}
      </NavDropdown.Item>
    </NavDropdown>
  );
};
