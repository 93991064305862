import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ServerError } from "features/errors/serverError.page";
import { FormEvent, useState } from "react";
import { Button, Form, FormControl, FormGroup, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiLockClosed } from "react-icons/hi";
import { Navigate } from "react-router-dom";
import { ILoginError } from "types";
import { getToken } from "utils/auth/getToken.helper";
import { isLoginError } from "utils/isValidationError.helper";
import { login } from "./login.query";

export const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { mutate, data, isLoading, error, isSuccess } = useMutation({
    mutationFn: login,
  });
  const queryClient = useQueryClient();

  // Redirect to homepage if logged in
  if (getToken() !== undefined) {
    return <Navigate to={t("fullRoutes.home")} />;
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({ email, password });
  };
  if (isSuccess) {
    const {
      data: { token },
    } = data;

    if (rememberMe) {
      localStorage.setItem("token", token);
    }
    sessionStorage.setItem("token", token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    queryClient.clear();
    return <Navigate to={t("fullRoutes.home")} />;
  }
  if (error !== null && !isLoginError(error)) {
    // todo: error handling
    return <ServerError />;
  }
  const errorFields = getErrorFields(error);
  return (
    <div className="login">
      <div className="login-banner"></div>
      <div className="login-form-cont">
        <Form className="login-form" onSubmit={handleSubmit}>
          <FormGroup className={"form-group"}>
            <FormControl
              isInvalid={errorFields.email || errorFields.credentials}
              type={"email"}
              placeholder={t("login.email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          {errorFields.email ? (
            <Form.Control.Feedback type="invalid">
              {t("login.invalidEmail")}
            </Form.Control.Feedback>
          ) : null}

          <FormGroup className={"form-group"}>
            <FormControl
              isInvalid={errorFields.password || errorFields.credentials}
              type={"password"}
              placeholder={t("login.password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {errorFields.credentials
                ? t("login.invalidCredentials")
                : t("login.invalidPassword")}
            </Form.Control.Feedback>
          </FormGroup>
          <a className="forgot-password" href="/todo">
            {/* todo */}
            <HiLockClosed /> {t("login.forgot")}
          </a>

          <FormGroup className={"form-group remember-me"}>
            <Form.Check
              id="remember-me"
              type={"checkbox"}
              label={t("login.stayLoggedIn")}
              checked={rememberMe}
              onChange={(e) => setRememberMe(!rememberMe)}
            />
          </FormGroup>
          <FormGroup className={"form-group"}>
            <Button variant="primary" type={"submit"} className="login-button">
              {isLoading ? <Spinner size="sm" /> : t("login.login")}
            </Button>
          </FormGroup>
        </Form>
        <div className="no-account">
          {t("login.noAccount")}
          <Button variant="outline-primary">{t("login.register")}</Button>
        </div>
      </div>
    </div>
  );
};

const getErrorFields = (error: ILoginError | null) => {
  return {
    credentials: Boolean(error?.response.data.non_field_errors),
    email: Boolean(error?.response.data.username),
    password: Boolean(error?.response.data.password),
  };
};
