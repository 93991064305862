import { useLanguage } from "hooks/useLanguage.hook";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IArticleAuthor } from "types";
import { getTranslation } from "utils/getTranslation.helper";
import wim from "../../../assets/images/wim.jpeg";

export const Expert = (props: IArticleAuthor) => {
  const language = useLanguage();
  const name = props.author.user.first_name + " " + props.author.user.last_name;
  const translation =
    getTranslation(props.author.expert_translation_set, language) ??
    props.author.expert_translation_set[0];
  return (
    <Nav>
      <Nav.Item className={"expert"}>
        <div className={"expert-img"}>
          <img src={wim} alt={name} />
        </div>
        <div className="expert-info">
          <Link to={`/nl/experten/${props.author.id}`}>
            <h6>{name}</h6>
          </Link>
          <p>{translation?.job_title}</p>
        </div>
      </Nav.Item>
    </Nav>
  );
};
