import { useMutation } from "@tanstack/react-query";
import { useLanguage } from "hooks/useLanguage.hook";
import { FormEvent, useState } from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Navigate } from "react-router-dom";
import { IArticleBody } from "types/article.types";
import { ArticleType } from "types/enums/articleType.enum";
import { isArticleError } from "utils/isValidationError.helper";
import { ArticleEditHeader } from "../editor/components/articleEditHeader.component";
import { DomainSelector } from "../editor/components/domainSelector.component";
import {
  ExpertPicker,
  IPickedExpert,
} from "../editor/components/expertPicker.component";
import {
  ChapterEditor,
  IEditChapter,
} from "./components/chapterEdit.component";
import { createArticle } from "./editArticle.query";

export const NewArticleEditor = () => {
  const { t } = useTranslation();
  const {
    mutate,
    data: updatedArticle,
    isLoading: isLoadingPost,
    error: createErrors,
    isSuccess: isSuccessPost,
  } = useMutation({
    mutationFn: createArticle,
  });

  const language = useLanguage();

  const [title, setTitle] = useState("");
  const [domain, setDomain] = useState("-1");
  const [invalidDomain, setInvalidDomain] = useState(false);
  const [linkWords, setLinkWords] = useState("");
  const [chapters, setChapters] = useState<IEditChapter[]>([
    { title: "", body: "", isFree: true, active: true },
  ]);

  const [selectedExperts, setSelectedExperts] = useState<IPickedExpert[]>([]);

  if (isSuccessPost) {
    return (
      <Navigate
        to={`${t("fullRoutes.allArticles")}/${updatedArticle.data.id}`}
      />
    );
  }

  const save = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (domain === "-1") {
      setInvalidDomain(true);
    }
    const sudomainId = Number(domain.split("/")[1]);
    const body: IArticleBody = {
      subdomain: sudomainId,
      type: ArticleType.article,
      article_author: selectedExperts.map((expert, i) => ({
        author: expert.id,
        order: i,
      })),
      chapters: chapters.map((chapter) => ({
        is_free: chapter.isFree,
        language,
        body: chapter.body,
        title: chapter.title,
      })),
      link_name_set: linkWords.split(",").map((linkName) => ({
        link_name: linkName.trim(),
        language,
      })),
      article_translation_set: [{ title, language }],
    };
    mutate({ body, language });
  };

  const errors = isArticleError(createErrors) ? createErrors.response.data : {};
  return (
    <Form onSubmit={save}>
      <article className={"edit-article"}>
        <ArticleEditHeader
          isUpdating={isLoadingPost}
          title={title}
          setTitle={setTitle}
          error={errors}
        />
        <div className={"edit-body"}>
          <div className="metadata">
            <div className={"input-cont"}>
              <DomainSelector
                domain={domain}
                setDomain={(newDomain) => {
                  setDomain(newDomain);
                  setInvalidDomain(false);
                }}
                isInvalid={invalidDomain}
              />

              <FormGroup className={"form-group"}>
                <FormLabel>Afkortingen, synoniemen</FormLabel>
                <FormControl
                  required={true}
                  type={"text"}
                  value={linkWords}
                  isInvalid={!!errors.link_name_set}
                  onChange={(e) => setLinkWords(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="input-cont expert-picker-cont">
              <ExpertPicker
                errors={errors}
                selectedExperts={selectedExperts}
                setSelectedExperts={setSelectedExperts}
              />
            </div>
          </div>

          <ChapterEditor
            setChapters={setChapters}
            chapters={chapters}
            errors={errors}
            disabled={isLoadingPost}
          />
        </div>
      </article>
    </Form>
  );
};
