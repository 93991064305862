import { useLanguage } from "hooks/useLanguage.hook";
import update from "immutability-helper";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { FaChevronDown, FaChevronRight, FaChevronUp } from "react-icons/fa";
import { GrEdit } from "react-icons/gr";
import { IArticle } from "types";
import {
  IDecisionNodeArticle,
  IDecisionNodeData,
  IDecisionNodeNode,
  mapDecisionNodeToDecisionNodeData,
} from "types/decisionTree.types";
import { Direction } from "types/enums/direction.enum";
import { getTranslation } from "utils/getTranslation.helper";
import { NodeModal, NodeType } from "./nodeModal.component";

const DecisionTreeEditor = (props: {
  article?: IArticle;
  rootNodes: IDecisionNodeData[];
  setRootNodes(nodes: IDecisionNodeData[]): void;
}) => {
  const { article, setRootNodes } = props;
  useEffect(() => {
    if (
      !article ||
      !article.decision_node_set ||
      Object.keys(article?.decision_node_set).length === 0
    ) {
      return;
    }

    const nodes: IDecisionNodeData[] =
      article.decision_node_set.map((node) =>
        mapDecisionNodeToDecisionNodeData(node, article.id)
      ) ?? [];

    setRootNodes(nodes);
  }, [article, setRootNodes]);

  return (
    <div className="decision-tree-editor">
      <Level setNodes={props.setRootNodes} nodes={props.rootNodes} />
    </div>
  );
};

export default React.memo(DecisionTreeEditor);

const Level = (
  props: PropsWithChildren<
    {
      nodes: IDecisionNodeData[];
      setNodes(nodes: IDecisionNodeData[]): void;
    } & (
      | { node?: undefined; updateNode?: undefined }
      | {
          node: IDecisionNodeNode;
          updateNode(node: IDecisionNodeNode): void;
          moveNode(direction: Direction): void;
          first: boolean;
          last: boolean;
        }
    )
  >
) => {
  const language = useLanguage();
  const { nodes, setNodes } = props;
  const filteredNodes = nodes
    .filter((node) => !node.deleted)
    .sort((a, b) => a.order - b.order);
  const children = useMemo(
    () =>
      filteredNodes.map((node, i) => {
        const updateNode = (
          updatedNode: IDecisionNodeArticle | IDecisionNodeNode | undefined
        ) => {
          console.log(filteredNodes);
          const newNodes = getNewNodes(filteredNodes, updatedNode, i);
          console.log(newNodes);
          return setNodes(newNodes);
        };

        const moveNode = (direction: Direction) => {
          const sign = direction === Direction.up ? -1 : 1;
          if (i + sign < 0 || i + sign >= filteredNodes.length) {
            return;
          }
          console.log(filteredNodes);

          const newNodes = update(filteredNodes, {
            [i]: { order: { $set: filteredNodes[i].order + sign } },
            [i + sign]: { order: { $set: filteredNodes[i].order } },
          });
          console.log(newNodes);

          return setNodes(newNodes);
        };
        if (node.type === NodeType.article) {
          return (
            <ArticleNode
              key={node.id ?? i}
              node={node as IDecisionNodeArticle}
              updateNode={updateNode}
              move={moveNode}
              first={i === 0}
              last={i === filteredNodes.length - 1}
            />
          );
        }
        console.log("Node ", i, " has order ", node.order);
        console.log("Rendering nodes ", filteredNodes);

        const setNodesNested = (nestedNodes: IDecisionNodeData[]) => {
          console.log("nested old: ", filteredNodes);
          console.log("Updating node", i, " with ", nestedNodes);

          const newNodes = update(filteredNodes, {
            [i]: { children: { $set: nestedNodes } },
          });
          console.log("nested new: ", newNodes);
          return setNodes(newNodes);
        };
        return (
          <Level
            key={node.id ?? i}
            node={node as IDecisionNodeNode}
            nodes={(node as IDecisionNodeNode).children}
            setNodes={setNodesNested}
            updateNode={updateNode}
            moveNode={moveNode}
            first={i === 0}
            last={i === filteredNodes.length - 1}
          />
        );
      }),
    [filteredNodes, setNodes]
  );

  const [collapsed, setCollapsed] = useState(!!props.node);
  const [show, setShow] = useState(false);

  const addNode = (node: Omit<IDecisionNodeData, "order">) => {
    const order = props.nodes.length;
    console.log("old", props.nodes);

    const newNodes = update(props.nodes, {
      $push: [{ ...node, order } as IDecisionNodeData],
    });
    console.log("new", newNodes);

    return props.setNodes(newNodes);
  };
  const name = getTranslation(
    props.node?.decision_node_translation_set,
    language
  )?.title;

  return (
    <div
      className={`${props.node && "node"} level ${
        collapsed ? "collapsed" : ""
      }`}
    >
      {props.node && (
        <div
          className="text"
          onClick={() => setCollapsed((collapsed) => !collapsed)}
        >
          <div className="left-cont">
            <MoveButtons
              move={props.moveNode}
              first={props.first}
              last={props.last}
            />
            <span>{name}</span>
            <GrEdit
              onClick={(e) => {
                setShow(true);
                e.stopPropagation();
              }}
              className="edit-article-icon"
            />
          </div>
          <CollapseIcon collapsed={collapsed} />
        </div>
      )}
      <div className="node">
        {props.node && props.updateNode && (
          <NodeModal
            show={show}
            close={() => setShow(false)}
            node={props.node}
            saveNode={props.updateNode}
          />
        )}
        {children}
        <AddNode addNode={addNode} />
      </div>
    </div>
  );
};

const CollapseIcon = (props: { collapsed: boolean }) => {
  if (props.collapsed) {
    return <FaChevronRight className={"collapse-icon"} onClick={(e) => {}} />;
  }
  return <FaChevronDown className={"collapse-icon"} onClick={(e) => {}} />;
};
const ArticleNode = (props: {
  node: IDecisionNodeArticle;
  updateNode(node: IDecisionNodeArticle): void;
  move(direction: Direction): void;
  first: boolean;
  last: boolean;
}) => {
  const [show, setShow] = useState(false);
  const language = useLanguage();
  const name =
    getTranslation(props.node.decision_node_translation_set, language)?.title ??
    "N/A";
  return (
    <div>
      <NodeModal
        show={show}
        close={() => {
          setShow(false);
        }}
        node={props.node}
        saveNode={props.updateNode}
      />
      <div onClick={() => setShow(true)} className="node article">
        <MoveButtons move={props.move} first={props.first} last={props.last} />
        <span>{name ?? "N/A"}</span>
      </div>
    </div>
  );
};

const AddNode = (props: { addNode(node: IDecisionNodeData): void }) => {
  const [show, setShow] = useState(false);

  return (
    <div>
      {show && (
        <NodeModal
          show={show}
          close={() => {
            setShow(false);
          }}
          saveNode={props.addNode}
        />
      )}
      <div onClick={() => setShow(true)} className="node add">
        <span>+</span>
      </div>
    </div>
  );
};

const getNewNodes = (
  nodes: IDecisionNodeData[],
  node: IDecisionNodeData | undefined,
  index: number
) => {
  if (node === undefined) {
    return update(nodes, {
      $splice: [[index, 1]],
    });
  }
  return update(nodes, {
    [index]: { $set: node },
  });
};

const MoveButtons = (props: {
  move(direction: Direction): void;
  first: boolean;
  last: boolean;
}) => {
  return (
    <div className="move-btns">
      <FaChevronUp
        className={props.first ? "disabled" : ""}
        onClick={(e) => {
          props.move(Direction.up);
          e.stopPropagation();
        }}
      />
      <FaChevronDown
        className={props.last ? "disabled" : ""}
        onClick={(e) => {
          props.move(Direction.down);
          e.stopPropagation();
        }}
      />
    </div>
  );
};
