import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoDocuments, IoPeople } from "react-icons/io5";
import { Form as RouterForm } from "react-router-dom";
import { HomepageButton } from "./components/homepageButton";
import { FaSearch } from "react-icons/fa";

export const Homepage = () => {
  const { t } = useTranslation();
  const checkSearchQuery = (e: React.MouseEvent<HTMLButtonElement>) => {
    const searchInput = document.querySelector<HTMLInputElement>(".search-bar input[type=search]");
    if (searchInput && searchInput.value.trim() === "") {
      e.preventDefault();
    }
  }
  const navigateToAskAI = () => {
    window.location.href = t("fullRoutes.askAI");
  }
  return (
    <div className={"homepage"}>
      <div className={"search-cont"}>
        <Form
          className="search-bar"
          as={RouterForm}
          method="get"
          action={t("fullRoutes.search")}
        >
          <Form.Control
            type="search"
            name="keywords"
            placeholder={t("homepage.search")}
            aria-label={t("homepage.search")}
          />
          <div className="search-bar-buttons">
            <Button className="search-button" variant={"link"} onClick={checkSearchQuery} type="submit"><FaSearch className="search-icon" /></Button>
            <div className="or">or</div>
            <Button type="button" className="ask-ai" onClick={navigateToAskAI} variant="primary">Ask AI</Button>

          </div>
        </Form>
      </div>
      <div className={"buttons-cont"}>
        <HomepageButton
          name={t("homepage.domains")}
          icon={IoDocuments}
          link={t("fullRoutes.domains")}
        />
        <HomepageButton
          name={t("homepage.experts")}
          icon={IoPeople}
          link={t("fullRoutes.experts")}
        />
        {/* <HomepageButton
          name={t("homepage.about")}
          icon={BsInfoSquareFill}
          link={t("fullRoutes.about")}
        /> */}
      </div>
    </div>
  );
};
