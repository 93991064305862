import { NodeType } from "features/admin/decision-tree/components/nodeModal.component";
import { IDecisionNode } from "types";
import { IArticleAuthorBody } from "./article.types";
import { Language } from "./enums/language.enum";

export interface IDecisionTreeData {
  article_translation_set: ArticleTranslation[];
  subdomain: number;
  article_author: IArticleAuthorBody[];
  link_name_set: LinkName[];
  decision_tree: IDecisionNodeData[];
}

interface ArticleTranslation {
  language: Language;
  title: string;
  decision_tree_description: string;
}
interface LinkName {
  link_name: string;
}

export interface IDecisionNodeArticle {
  order: number;
  decision_node_translation_set: DecisionNodeTranslation[];
  type: NodeType.article;
  article: number;
}
export interface IDecisionNodeNode {
  order: number;
  decision_node_translation_set: DecisionNodeTranslation[];
  children: IDecisionNodeData[];
  type: NodeType.node;
  article: null;
}

export type IDecisionNodeData = {
  deleted?: boolean;
  id?: number;
  order: number;
  decision_node_translation_set: DecisionNodeTranslation[];
  children?: IDecisionNodeData[];
  type: NodeType;
  article: number | null;
};

interface DecisionNodeTranslation {
  language: Language;
  title: string;
}

export const mapDecisionNodeToDecisionNodeData = (
  node: IDecisionNode,
  articleId: number
): IDecisionNodeData => {
  return {
    id: node.id,
    order: node.order,
    decision_node_translation_set: node.decision_node_translation_set,
    children: node.children.map((child) =>
      mapDecisionNodeToDecisionNodeData(child, articleId)
    ),
    type: node.type,
    article: node.type === NodeType.article ? node.article : null,
  };
};
