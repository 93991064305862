import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "hooks/useLanguage.hook";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { IDomain, ISubdomain } from "types";
import { Language } from "types/enums/language.enum";
import { getDomainBySlug, getDomainData } from "utils";
import { getDomains } from "../domain.query";
import { DomainItem } from "./domainItem";

export const DomainList = () => {
  const { domainSlug } = useParams<{ domainSlug?: string }>();
  const language = useLanguage();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["domain"],
    queryFn: () => getDomains(language),
    cacheTime: 5 * 60 * 1000,
  });

  if (isLoading) {
    const skeletons = Array(6)
      .fill(1)
      .map((_, i) => <Skeleton containerClassName="list-item" key={i} />);
    return <ul className={"list"}>{skeletons}</ul>;
  } else if (isError) {
    return <div>Something went wrong</div>;
  }

  const items = getList(data, domainSlug, language);

  const domainItems = (items ?? []).map((domain) => {
    const [name, link] = getDomainData(language, domain, domainSlug);
    return <DomainItem name={name} link={link} key={domain.name} />;
  });
  return <ul className={"list"}>{domainItems}</ul>;
};

const getList = (
  items: IDomain[],
  domainSlug: string | undefined,
  language: Language
): IDomain[] | ISubdomain[] | undefined => {
  if (domainSlug === undefined) {
    return items;
  }
  const domain = getDomainBySlug(items, domainSlug, language);
  return domain?.subdomain_set;
};
